/**
 * =============================================================================
 * ************   寮€鏀剧殑甯哥敤鏂规硶   ************
 * =============================================================================
 */

$.fn.extend({

  /**
   * 鎵ц寮哄埗閲嶇粯
   */
  reflow: function () {
    return this.each(function () {
      return this.clientLeft;
    });
  },

  /**
   * 璁剧疆 transition 鏃堕棿
   * @param duration
   */
  transition: function (duration) {
    if (typeof duration !== 'string') {
      duration = duration + 'ms';
    }

    return this.each(function () {
      this.style.webkitTransitionDuration = duration;
      this.style.transitionDuration = duration;
    });
  },

  /**
   * transition 鍔ㄧ敾缁撴潫鍥炶皟
   * @param callback
   * @returns {transitionEnd}
   */
  transitionEnd: function (callback) {
    var events = [
        'webkitTransitionEnd',
        'transitionend',
      ];
    var i;
    var _this = this;

    function fireCallBack(e) {
      if (e.target !== this) {
        return;
      }

      callback.call(this, e);

      for (i = 0; i < events.length; i++) {
        _this.off(events[i], fireCallBack);
      }
    }

    if (callback) {
      for (i = 0; i < events.length; i++) {
        _this.on(events[i], fireCallBack);
      }
    }

    return this;
  },

  /**
   * 璁剧疆 transform-origin 灞炴€n   * @param transformOrigin
   */
  transformOrigin: function (transformOrigin) {
    return this.each(function () {
      this.style.webkitTransformOrigin = transformOrigin;
      this.style.transformOrigin = transformOrigin;
    });
  },

  /**
   * 璁剧疆 transform 灞炴€n   * @param transform
   */
  transform: function (transform) {
    return this.each(function () {
      this.style.webkitTransform = transform;
      this.style.transform = transform;
    });
  },

});

$.extend({
  /**
   * 鍒涘缓骞舵樉绀洪伄缃‐n   * @param zIndex 閬僵灞傜殑 z-index
   */
  showOverlay: function (zIndex) {
    var $overlay = $('.mdui-overlay');

    if ($overlay.length) {
      $overlay.data('isDeleted', 0);

      if (zIndex !== undefined) {
        $overlay.css('z-index', zIndex);
      }
    } else {
      if (zIndex === undefined) {
        zIndex = 2000;
      }

      $overlay = $('<div class="mdui-overlay">')
        .appendTo(document.body)
        .reflow()
        .css('z-index', zIndex);
    }

    var level = $overlay.data('overlay-level') || 0;
    return $overlay
      .data('overlay-level', ++level)
      .addClass('mdui-overlay-show');
  },

  /**
   * 闅愯棌閬僵灞俓n   * @param force 鏄惁寮哄埗闅愯棌閬僵
   */
  hideOverlay: function (force) {
    var $overlay = $('.mdui-overlay');

    if (!$overlay.length) {
      return;
    }

    var level = force ? 1 : $overlay.data('overlay-level');
    if (level > 1) {
      $overlay.data('overlay-level', --level);
      return;
    }

    $overlay
      .data('overlay-level', 0)
      .removeClass('mdui-overlay-show')
      .data('isDeleted', 1)
      .transitionEnd(function () {
        if ($overlay.data('isDeleted')) {
          $overlay.remove();
        }
      });
  },

  /**
   * 閿佸畾灞忓箷
   */
  lockScreen: function () {
    var $body = $('body');

    // 涓嶇洿鎺ユ妸 body 璁句负 box-sizing: border-box锛岄伩鍏嶆薄鏌撳叏灞€鏍峰紡
    var newBodyWidth = $body.width();

    $body
      .addClass('mdui-locked')
      .width(newBodyWidth);

    var level = $body.data('lockscreen-level') || 0;
    $body.data('lockscreen-level', ++level);
  },

  /**
   * 瑙ｉ櫎灞忓箷閿佸畾
   * @param force 鏄惁寮哄埗瑙ｉ攣灞忓箷
   */
  unlockScreen: function (force) {
    var $body = $('body');

    var level = force ? 1 : $body.data('lockscreen-level');
    if (level > 1) {
      $body.data('lockscreen-level', --level);
      return;
    }

    $body
      .data('lockscreen-level', 0)
      .removeClass('mdui-locked')
      .width('');
  },

  /**
   * 鍑芥暟鑺傛祦
   * @param fn
   * @param delay
   * @returns {Function}
   */
  throttle: function (fn, delay) {
    var timer = null;
    if (!delay || delay < 16) {
      delay = 16;
    }

    return function () {
      var _this = this;
      var args = arguments;

      if (timer === null) {
        timer = setTimeout(function () {
          fn.apply(_this, args);
          timer = null;
        }, delay);
      }
    };
  },
});

/**
 * 鐢熸垚鍞竴 id
 * @param string name id鐨勫悕绉帮紝鑻ヨ鍚嶇О瀵逛簬鐨刧uid涓嶅瓨鍦紝鍒欑敓鎴愭柊鐨刧uid骞惰繑鍥烇紱鑻ュ凡瀛樺湪锛屽垯杩斿洖鍘熸湁guid
 * @returns {string}
 */
(function () {
  var GUID = {};

  $.extend({
    guid: function (name) {
      if (typeof name !== 'undefined' && typeof GUID[name] !== 'undefined') {
        return GUID[name];
      }

      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }

      var guid = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();

      if (typeof name !== 'undefined') {
        GUID[name] = guid;
      }

      return guid;
    },
  });
})();

/**
 * =============================================================================
 * ************   ToolTip 宸ュ叿鎻愮ず   ************
 * =============================================================================
 */

mdui.Tooltip = (function () {

  /**
   * 榛樿鍙傛暟
   */
  var DEFAULT = {
    position: 'auto',     // 鎻愮ず鎵€鍦ㄤ綅缃甛n    delay: 0,             // 寤惰繜锛屽崟浣嶆绉抃n    content: '',          // 鎻愮ず鏂囨湰锛屽厑璁稿寘鍚 HTML
  };

  /**
   * 鏄惁鏄闈㈣澶嘰n   * @returns {boolean}
   */
  var isDesktop = function () {
    return $window.width() > 1024;
  };

  /**
   * 璁剧疆 Tooltip 鐨勪綅缃甛n   * @param inst
   */
  function setPosition(inst) {
    var marginLeft;
    var marginTop;
    var position;

    // 瑙﹀彂鐨勫厓绱燶n    var targetProps = inst.$target[0].getBoundingClientRect();

    // 瑙﹀彂鐨勫厓绱犲拰 Tooltip 涔嬮棿鐨勮窛绂籠n    var targetMargin = (isDesktop() ? 14 : 24);

    // Tooltip 鐨勫搴﹀拰楂樺害
    var tooltipWidth = inst.$tooltip[0].offsetWidth;
    var tooltipHeight = inst.$tooltip[0].offsetHeight;

    // Tooltip 鐨勬柟鍚慭n    position = inst.options.position;

    // 鑷姩鍒ゆ柇浣嶇疆锛屽姞 2px锛屼娇 Tooltip 璺濈绐楀彛杈规鑷冲皯鏈 2px 鐨勯棿璺漒n    if (['bottom', 'top', 'left', 'right'].indexOf(position) === -1) {
      if (
        targetProps.top + targetProps.height + targetMargin + tooltipHeight + 2 <
        $window.height()
      ) {
        position = 'bottom';
      } else if (targetMargin + tooltipHeight + 2 < targetProps.top) {
        position = 'top';
      } else if (targetMargin + tooltipWidth + 2 < targetProps.left) {
        position = 'left';
      } else if (
        targetProps.width + targetMargin + tooltipWidth + 2 <
        $window.width() - targetProps.left
      ) {
        position = 'right';
      } else {
        position = 'bottom';
      }
    }

    // 璁剧疆浣嶇疆
    switch (position) {
      case 'bottom':
        marginLeft = -1 * (tooltipWidth / 2);
        marginTop = (targetProps.height / 2) + targetMargin;
        inst.$tooltip.transformOrigin('top center');
        break;
      case 'top':
        marginLeft = -1 * (tooltipWidth / 2);
        marginTop = -1 * (tooltipHeight + (targetProps.height / 2) + targetMargin);
        inst.$tooltip.transformOrigin('bottom center');
        break;
      case 'left':
        marginLeft = -1 * (tooltipWidth + (targetProps.width / 2) + targetMargin);
        marginTop = -1 * (tooltipHeight / 2);
        inst.$tooltip.transformOrigin('center right');
        break;
      case 'right':
        marginLeft = (targetProps.width / 2) + targetMargin;
        marginTop = -1 * (tooltipHeight / 2);
        inst.$tooltip.transformOrigin('center left');
        break;
    }

    var targetOffset = inst.$target.offset();
    inst.$tooltip.css({
      top: targetOffset.top + (targetProps.height / 2) + 'px',
      left: targetOffset.left + (targetProps.width / 2) + 'px',
      'margin-left': marginLeft + 'px',
      'margin-top': marginTop + 'px',
    });
  }

  /**
   * Tooltip 瀹炰緥
   * @param selector
   * @param opts
   * @constructor
   */
  function Tooltip(selector, opts) {
    var _this = this;

    _this.$target = $(selector).eq(0);
    if (!_this.$target.length) {
      return;
    }

    // 宸查€氳繃 data 灞炴€у疄渚嬪寲杩囷紝涓嶅啀閲嶅瀹炰緥鍖朶n    var oldInst = _this.$target.data('mdui.tooltip');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.state = 'closed';

    // 鍒涘缓 Tooltip HTML
    _this.$tooltip = $(
      '<div class="mdui-tooltip" id="' + $.guid() + '">' +
        _this.options.content +
      '</div>'
    ).appendTo(document.body);

    // 缁戝畾浜嬩欢銆傚厓绱犲浜 disabled 鐘舵€佹椂鏃犳硶瑙﹀彂榧犳爣浜嬩欢锛屼负浜嗙粺涓€锛屾妸 touch 浜嬩欢涔熺鐢╘n    _this.$target
      .on('touchstart mouseenter', function (e) {
        if (this.disabled) {
          return;
        }

        if (!TouchHandler.isAllow(e)) {
          return;
        }

        TouchHandler.register(e);

        _this.open();
      })
      .on('touchend mouseleave', function (e) {
        if (this.disabled) {
          return;
        }

        if (!TouchHandler.isAllow(e)) {
          return;
        }

        _this.close();
      })
      .on(TouchHandler.unlock, function (e) {
        if (this.disabled) {
          return;
        }

        TouchHandler.register(e);
      });
  }

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   * @private
   */
  var transitionEnd = function (inst) {
    if (inst.$tooltip.hasClass('mdui-tooltip-open')) {
      inst.state = 'opened';
      componentEvent('opened', 'tooltip', inst, inst.$target);
    } else {
      inst.state = 'closed';
      componentEvent('closed', 'tooltip', inst, inst.$target);
    }
  };

  /**
   * 鎵ц鎵撳紑 Tooltip
   * @private
   */
  Tooltip.prototype._doOpen = function () {
    var _this = this;

    _this.state = 'opening';
    componentEvent('open', 'tooltip', _this, _this.$target);

    _this.$tooltip
      .addClass('mdui-tooltip-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * 鎵撳紑 Tooltip
   * @param opts 鍏佽姣忔鎵撳紑鏃惰缃笉鍚岀殑鍙傛暟
   */
  Tooltip.prototype.open = function (opts) {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    var oldOpts = $.extend({}, _this.options);

    // 鍚堝苟 data 灞炴€у弬鏁癨n    $.extend(_this.options, parseOptions(_this.$target.attr('mdui-tooltip')));
    if (opts) {
      $.extend(_this.options, opts);
    }

    // tooltip 鐨勫唴瀹规湁鏇存柊
    if (oldOpts.content !== _this.options.content) {
      _this.$tooltip.html(_this.options.content);
    }

    setPosition(_this);

    if (_this.options.delay) {
      _this.timeoutId = setTimeout(function () {
        _this._doOpen();
      }, _this.options.delay);
    } else {
      _this.timeoutId = false;
      _this._doOpen();
    }
  };

  /**
   * 鍏抽棴 Tooltip
   */
  Tooltip.prototype.close = function () {
    var _this = this;

    if (_this.timeoutId) {
      clearTimeout(_this.timeoutId);
      _this.timeoutId = false;
    }

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    _this.state = 'closing';
    componentEvent('close', 'tooltip', _this, _this.$target);

    _this.$tooltip
      .removeClass('mdui-tooltip-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * 鍒囨崲 Tooltip 鐘舵€乗n   */
  Tooltip.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * 鑾峰彇 Tooltip 鐘舵€乗n   * @returns {'opening'|'opened'|'closing'|'closed'}
   */
  Tooltip.prototype.getState = function () {
    return this.state;
  };

  /**
   * 閿€姣 Tooltip
   */
  /*Tooltip.prototype.destroy = function () {
    var _this = this;
    clearTimeout(_this.timeoutId);
    $.data(_this.target, 'mdui.tooltip', null);
    $.remove(_this.tooltip);
  };*/

  return Tooltip;

})();

/**
 * =============================================================================
 * ************   Drawer 鎶藉眽鏍   ************
 * =============================================================================
 *
 * 鍦ㄦ闈㈣澶囦笂榛樿鏄剧ず鎶藉眽鏍忥紝涓嶆樉绀洪伄缃╁眰
 * 鍦ㄦ墜鏈哄拰骞虫澘璁惧涓婇粯璁や笉鏄剧ず鎶藉眽鏍忥紝濮嬬粓鏄剧ず閬僵灞傦紝涓旇鐩栧鑸爮
 */

mdui.Drawer = (function () {

  /**
   * 榛樿鍙傛暟
   * @type {{}}
   */
  var DEFAULT = {
    // 鍦ㄦ闈㈣澶囦笂鏄惁鏄剧ず閬僵灞傘€傛墜鏈哄拰骞虫澘涓嶅彈杩欎釜鍙傛暟褰卞搷锛屽缁堜細鏄剧ず閬僵灞俓n    overlay: false,

    // 鏄惁寮€鍚墜鍔縗n    swipe: false,
  };

  var isDesktop = function () {
    return $window.width() >= 1024;
  };

  /**
   * 鎶藉眽鏍忓疄渚媆n   * @param selector 閫夋嫨鍣ㄦ垨 HTML 瀛楃涓叉垨 DOM 鍏冪礌
   * @param opts
   * @constructor
   */
  function Drawer(selector, opts) {
    var _this = this;

    _this.$drawer = $(selector).eq(0);
    if (!_this.$drawer.length) {
      return;
    }

    var oldInst = _this.$drawer.data('mdui.drawer');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));

    _this.overlay = false; // 鏄惁鏄剧ず鐫€閬僵灞俓n    _this.position = _this.$drawer.hasClass('mdui-drawer-right') ? 'right' : 'left';

    if (_this.$drawer.hasClass('mdui-drawer-close')) {
      _this.state = 'closed';
    } else if (_this.$drawer.hasClass('mdui-drawer-open')) {
      _this.state = 'opened';
    } else if (isDesktop()) {
      _this.state = 'opened';
    } else {
      _this.state = 'closed';
    }

    // 娴忚鍣ㄧ獥鍙ｅぇ灏忚皟鏁存椂
    $window.on('resize', $.throttle(function () {
      // 鐢辨墜鏈哄钩鏉垮垏鎹㈠埌妗岄潰鏃禱n      if (isDesktop()) {
        // 濡傛灉鏄剧ず鐫€閬僵锛屽垯闅愯棌閬僵
        if (_this.overlay && !_this.options.overlay) {
          $.hideOverlay();
          _this.overlay = false;
          $.unlockScreen();
        }

        // 娌℃湁寮哄埗鍏抽棴锛屽垯鐘舵€佷负鎵撳紑鐘舵€乗n        if (!_this.$drawer.hasClass('mdui-drawer-close')) {
          _this.state = 'opened';
        }
      }

      // 鐢辨闈㈠垏鎹㈠埌鎵嬫満骞虫澘鏃躲€傚鏋滄娊灞夋爮鏄墦寮€鐫€鐨勪笖娌℃湁閬僵灞傦紝鍒欏叧闂娊灞夋爮
      else {
        if (!_this.overlay && _this.state === 'opened') {
          // 鎶藉眽鏍忓浜庡己鍒舵墦寮€鐘舵€侊紝娣诲姞閬僵
          if (_this.$drawer.hasClass('mdui-drawer-open')) {
            $.showOverlay();
            _this.overlay = true;
            $.lockScreen();

            $('.mdui-overlay').one('click', function () {
              _this.close();
            });
          } else {
            _this.state = 'closed';
          }
        }
      }
    }, 100));

    // 缁戝畾鍏抽棴鎸夐挳浜嬩欢
    _this.$drawer.find('[mdui-drawer-close]').each(function () {
      $(this).on('click', function () {
        _this.close();
      });
    });

    swipeSupport(_this);
  }

  /**
   * 婊戝姩鎵嬪娍鏀寔
   * @param _this
   */
  var swipeSupport = function (_this) {
    // 鎶藉眽鏍忔粦鍔ㄦ墜鍔挎帶鍒禱n    var openNavEventHandler;
    var touchStartX;
    var touchStartY;
    var swipeStartX;
    var swiping = false;
    var maybeSwiping = false;
    var $body = $('body');

    // 鎵嬪娍瑙﹀彂鐨勮寖鍥碶n    var swipeAreaWidth = 24;

    function enableSwipeHandling() {
      if (!openNavEventHandler) {
        $body.on('touchstart', onBodyTouchStart);
        openNavEventHandler = onBodyTouchStart;
      }
    }

    function setPosition(translateX, closeTransform) {
      var rtlTranslateMultiplier = _this.position === 'right' ? -1 : 1;
      var transformCSS = 'translate(' + (-1 * rtlTranslateMultiplier * translateX) + 'px, 0) !important;';
      _this.$drawer.css(
        'cssText',
        'transform:' + transformCSS + (closeTransform ? 'transition: initial !important;' : '')
      );
    }

    function cleanPosition() {
      _this.$drawer.css({
        transform: '',
        transition: '',
      });
    }

    function getMaxTranslateX() {
      return _this.$drawer.width() + 10;
    }

    function getTranslateX(currentX) {
      return Math.min(
        Math.max(
          swiping === 'closing' ? (swipeStartX - currentX) : (getMaxTranslateX() + swipeStartX - currentX),
          0
        ),
        getMaxTranslateX()
      );
    }

    function onBodyTouchStart(event) {
      touchStartX = event.touches[0].pageX;
      if (_this.position === 'right') {
        touchStartX = $body.width() - touchStartX;
      }

      touchStartY = event.touches[0].pageY;

      if (_this.state !== 'opened') {
        if (touchStartX > swipeAreaWidth || openNavEventHandler !== onBodyTouchStart) {
          return;
        }
      }

      maybeSwiping = true;

      $body.on({
        touchmove: onBodyTouchMove,
        touchend: onBodyTouchEnd,
        touchcancel: onBodyTouchMove,
      });
    }

    function onBodyTouchMove(event) {
      var touchX = event.touches[0].pageX;
      if (_this.position === 'right') {
        touchX = $body.width() - touchX;
      }

      var touchY = event.touches[0].pageY;

      if (swiping) {
        setPosition(getTranslateX(touchX), true);
      } else if (maybeSwiping) {
        var dXAbs = Math.abs(touchX - touchStartX);
        var dYAbs = Math.abs(touchY - touchStartY);
        var threshold = 8;

        if (dXAbs > threshold && dYAbs <= threshold) {
          swipeStartX = touchX;
          swiping = _this.state === 'opened' ? 'closing' : 'opening';
          $.lockScreen();
          setPosition(getTranslateX(touchX), true);
        } else if (dXAbs <= threshold && dYAbs > threshold) {
          onBodyTouchEnd();
        }
      }
    }

    function onBodyTouchEnd(event) {
      if (swiping) {
        var touchX = event.changedTouches[0].pageX;
        if (_this.position === 'right') {
          touchX = $body.width() - touchX;
        }

        var translateRatio = getTranslateX(touchX) / getMaxTranslateX();

        maybeSwiping = false;
        var swipingState = swiping;
        swiping = null;

        if (swipingState === 'opening') {
          if (translateRatio < 0.92) {
            cleanPosition();
            _this.open();
          } else {
            cleanPosition();
          }
        } else {
          if (translateRatio > 0.08) {
            cleanPosition();
            _this.close();
          } else {
            cleanPosition();
          }
        }

        $.unlockScreen();
      } else {
        maybeSwiping = false;
      }

      $body.off({
        touchmove: onBodyTouchMove,
        touchend: onBodyTouchEnd,
        touchcancel: onBodyTouchMove,
      });
    }

    if (_this.options.swipe) {
      enableSwipeHandling();
    }
  };

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   * @param inst
   */
  var transitionEnd = function (inst) {
    if (inst.$drawer.hasClass('mdui-drawer-open')) {
      inst.state = 'opened';
      componentEvent('opened', 'drawer', inst, inst.$drawer);
    } else {
      inst.state = 'closed';
      componentEvent('closed', 'drawer', inst, inst.$drawer);
    }
  };

  /**
   * 鎵撳紑鎶藉眽鏍廫n   */
  Drawer.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    _this.state = 'opening';
    componentEvent('open', 'drawer', _this, _this.$drawer);

    if (!_this.options.overlay) {
      $('body').addClass('mdui-drawer-body-' + _this.position);
    }

    _this.$drawer
      .removeClass('mdui-drawer-close')
      .addClass('mdui-drawer-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });

    if (!isDesktop() || _this.options.overlay) {
      _this.overlay = true;
      $.showOverlay().one('click', function () {
        _this.close();
      });

      $.lockScreen();
    }
  };

  /**
   * 鍏抽棴鎶藉眽鏍廫n   */
  Drawer.prototype.close = function () {
    var _this = this;

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    _this.state = 'closing';
    componentEvent('close', 'drawer', _this, _this.$drawer);

    if (!_this.options.overlay) {
      $('body').removeClass('mdui-drawer-body-' + _this.position);
    }

    _this.$drawer
      .addClass('mdui-drawer-close')
      .removeClass('mdui-drawer-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });

    if (_this.overlay) {
      $.hideOverlay();
      _this.overlay = false;
      $.unlockScreen();
    }
  };

  /**
   * 鍒囨崲鎶藉眽鏍忔墦寮€/鍏抽棴鐘舵€乗n   */
  Drawer.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * 鑾峰彇鎶藉眽鏍忕姸鎬乗n   * @returns {'opening'|'opened'|'closing'|'closed'}
   */
  Drawer.prototype.getState = function () {
    return this.state;
  };

  return Drawer;

})();

/**
 * =============================================================================
 * ************   Fab DATA API   ************
 * =============================================================================
 */

$(function () {
  // mouseenter 涓嶅啋娉★紝鏃犳硶杩涜浜嬩欢濮旀墭锛岃繖閲岀敤 mouseover 浠ｆ浛銆俓n  // 涓嶇鏄 click 銆 mouseover 杩樻槸 touchstart 锛岄兘鍏堝垵濮嬪寲銆俓n
  $document.on('touchstart mousedown mouseover', '[mdui-fab]', function (e) {
    var $this = $(this);

    var inst = $this.data('mdui.fab');
    if (!inst) {
      var options = parseOptions($this.attr('mdui-fab'));
      inst = new mdui.Fab($this, options);
      $this.data('mdui.fab', inst);
    }
  });
});

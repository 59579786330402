/**
 * =============================================================================
 * ************   Menu 鑿滃崟   ************
 * =============================================================================
 */

mdui.Menu = (function () {

  /**
   * 榛樿鍙傛暟
   */
  var DEFAULT = {
    position: 'auto',         // 鑿滃崟浣嶇疆 top銆乥ottom銆乧enter銆乤uto
    align: 'auto',            // 鑿滃崟鍜岃Е鍙戝畠鐨勫厓绱犵殑瀵归綈鏂瑰紡 left銆乺ight銆乧enter銆乤uto
    gutter: 16,               // 鑿滃崟璺濈绐楀彛杈圭紭鐨勬渶灏忚窛绂伙紝鍗曚綅 px
    fixed: false,             // 鏄惁浣胯彍鍗曞浐瀹氬湪绐楀彛锛屼笉闅忔粴鍔ㄦ潯婊氬姩
    covered: 'auto',          // 鑿滃崟鏄惁瑕嗙洊鍦ㄨЕ鍙戝畠鐨勫厓绱犱笂锛宼rue銆乫alse銆俛uto 鏃剁畝鍗曡彍鍗曡鐩栵紝绾ц仈鑿滃崟涓嶈鐩朶n    subMenuTrigger: 'hover',  // 瀛愯彍鍗曠殑瑙﹀彂鏂瑰紡 hover銆乧lick
    subMenuDelay: 200,        // 瀛愯彍鍗曠殑瑙﹀彂寤舵椂锛屼粎鍦 submenuTrigger 涓 hover 鏈夋晥
  };

  /**
   * 璋冩暣涓昏彍鍗曚綅缃甛n   * @param _this 瀹炰緥
   */
  var readjust = function (_this) {
    var menuLeft;
    var menuTop;

    // 鑿滃崟浣嶇疆鍜屾柟鍚慭n    var position;
    var align;

    // window 绐楀彛鐨勫搴﹀拰楂樺害
    var windowHeight = $window.height();
    var windowWidth = $window.width();

    // 閰嶇疆鍙傛暟
    var gutter = _this.options.gutter;
    var isCovered = _this.isCovered;
    var isFixed = _this.options.fixed;

    // 鍔ㄧ敾鏂瑰悜鍙傛暟
    var transformOriginX;
    var transformOriginY;

    // 鑿滃崟鐨勫師濮嬪搴﹀拰楂樺害
    var menuWidth = _this.$menu.width();
    var menuHeight = _this.$menu.height();

    var $anchor = _this.$anchor;

    // 瑙﹀彂鑿滃崟鐨勫厓绱犲湪绐楀彛涓殑浣嶇疆
    var anchorTmp = $anchor[0].getBoundingClientRect();
    var anchorTop = anchorTmp.top;
    var anchorLeft = anchorTmp.left;
    var anchorHeight = anchorTmp.height;
    var anchorWidth = anchorTmp.width;
    var anchorBottom = windowHeight - anchorTop - anchorHeight;
    var anchorRight = windowWidth - anchorLeft - anchorWidth;

    // 瑙﹀彂鍏冪礌鐩稿鍏舵嫢鏈夊畾浣嶅睘鎬х殑鐖跺厓绱犵殑浣嶇疆
    var anchorOffsetTop = $anchor[0].offsetTop;
    var anchorOffsetLeft = $anchor[0].offsetLeft;

    // ===============================
    // ================= 鑷姩鍒ゆ柇鑿滃崟浣嶇疆
    // ===============================
    if (_this.options.position === 'auto') {

      // 鍒ゆ柇涓嬫柟鏄惁鏀惧緱涓嬭彍鍗昞n      if (anchorBottom + (isCovered ? anchorHeight : 0) > menuHeight + gutter) {
        position = 'bottom';
      }

      // 鍒ゆ柇涓婃柟鏄惁鏀惧緱涓嬭彍鍗昞n      else if (anchorTop + (isCovered ? anchorHeight : 0) > menuHeight + gutter) {
        position = 'top';
      }

      // 涓婁笅閮芥斁涓嶄笅锛屽眳涓樉绀篭n      else {
        position = 'center';
      }
    } else {
      position = _this.options.position;
    }

    // ===============================
    // ============== 鑷姩鍒ゆ柇鑿滃崟瀵归綈鏂瑰紡
    // ===============================
    if (_this.options.align === 'auto') {

      // 鍒ゆ柇鍙充晶鏄惁鏀惧緱涓嬭彍鍗昞n      if (anchorRight + anchorWidth > menuWidth + gutter) {
        align = 'left';
      }

      // 鍒ゆ柇宸︿晶鏄惁鏀惧緱涓嬭彍鍗昞n      else if (anchorLeft + anchorWidth > menuWidth + gutter) {
        align = 'right';
      }

      // 宸﹀彸閮芥斁涓嶄笅锛屽眳涓樉绀篭n      else {
        align = 'center';
      }
    } else {
      align = _this.options.align;
    }

    // ===============================
    // ==================== 璁剧疆鑿滃崟浣嶇疆
    // ===============================
    if (position === 'bottom') {
      transformOriginY = '0';

      menuTop =
        (isCovered ? 0 : anchorHeight) +
        (isFixed ? anchorTop : anchorOffsetTop);

    } else if (position === 'top') {
      transformOriginY = '100%';

      menuTop =
        (isCovered ? anchorHeight : 0) +
        (isFixed ? (anchorTop - menuHeight) : (anchorOffsetTop - menuHeight));

    } else {
      transformOriginY = '50%';

      // =====================鍦ㄧ獥鍙ｄ腑灞呬腑
      // 鏄剧ず鐨勮彍鍗曠殑楂樺害锛岀畝鍗曡彍鍗曢珮搴︿笉瓒呰繃绐楀彛楂樺害锛岃嫢瓒呰繃浜嗗垯鍦ㄨ彍鍗曞唴閮ㄦ樉绀烘粴鍔ㄦ潯
      // 绾ц仈鑿滃崟鍐呴儴涓嶅厑璁稿嚭鐜版粴鍔ㄦ潯
      var menuHeightTemp = menuHeight;

      // 绠€鍗曡彍鍗曟瘮绐楀彛楂樻椂锛岄檺鍒惰彍鍗曢珮搴n      if (!_this.isCascade) {
        if (menuHeight + gutter * 2 > windowHeight) {
          menuHeightTemp = windowHeight - gutter * 2;
          _this.$menu.height(menuHeightTemp);
        }
      }

      menuTop =
        (windowHeight - menuHeightTemp) / 2 +
        (isFixed ? 0 : (anchorOffsetTop - anchorTop));
    }

    _this.$menu.css('top', menuTop + 'px');

    // ===============================
    // ================= 璁剧疆鑿滃崟瀵归綈鏂瑰紡
    // ===============================
    if (align === 'left') {
      transformOriginX = '0';

      menuLeft = isFixed ? anchorLeft : anchorOffsetLeft;

    } else if (align === 'right') {
      transformOriginX = '100%';

      menuLeft = isFixed ?
        (anchorLeft + anchorWidth - menuWidth) :
        (anchorOffsetLeft + anchorWidth - menuWidth);
    } else {
      transformOriginX = '50%';

      //=======================鍦ㄧ獥鍙ｄ腑灞呬腑
      // 鏄剧ず鐨勮彍鍗曠殑瀹藉害锛岃彍鍗曞搴︿笉鑳借秴杩囩獥鍙ｅ搴n      var menuWidthTemp = menuWidth;

      // 鑿滃崟姣旂獥鍙ｅ锛岄檺鍒惰彍鍗曞搴n      if (menuWidth + gutter * 2 > windowWidth) {
        menuWidthTemp = windowWidth - gutter * 2;
        _this.$menu.width(menuWidthTemp);
      }

      menuLeft =
        (windowWidth - menuWidthTemp) / 2 +
        (isFixed ? 0 : anchorOffsetLeft - anchorLeft);
    }

    _this.$menu.css('left', menuLeft + 'px');

    // 璁剧疆鑿滃崟鍔ㄧ敾鏂瑰悜
    _this.$menu.transformOrigin(transformOriginX + ' ' + transformOriginY);
  };

  /**
   * 璋冩暣瀛愯彍鍗曠殑浣嶇疆
   * @param $submenu
   */
  var readjustSubmenu = function ($submenu) {
    var $item = $submenu.parent('.mdui-menu-item');

    var submenuTop;
    var submenuLeft;

    // 瀛愯彍鍗曚綅缃拰鏂瑰悜
    var position; // top銆乥ottom
    var align; // left銆乺ight

    // window 绐楀彛鐨勫搴﹀拰楂樺害
    var windowHeight = $window.height();
    var windowWidth = $window.width();

    // 鍔ㄧ敾鏂瑰悜鍙傛暟
    var transformOriginX;
    var transformOriginY;

    // 瀛愯彍鍗曠殑鍘熷瀹藉害鍜岄珮搴n    var submenuWidth = $submenu.width();
    var submenuHeight = $submenu.height();

    // 瑙﹀彂瀛愯彍鍗曠殑鑿滃崟椤圭殑瀹藉害楂樺害
    var itemTmp = $item[0].getBoundingClientRect();
    var itemWidth = itemTmp.width;
    var itemHeight = itemTmp.height;
    var itemLeft = itemTmp.left;
    var itemTop = itemTmp.top;

    // ===================================
    // ===================== 鍒ゆ柇鑿滃崟涓婁笅浣嶇疆
    // ===================================

    // 鍒ゆ柇涓嬫柟鏄惁鏀惧緱涓嬭彍鍗昞n    if (windowHeight - itemTop > submenuHeight) {
      position = 'bottom';
    }

    // 鍒ゆ柇涓婃柟鏄惁鏀惧緱涓嬭彍鍗昞n    else if (itemTop + itemHeight > submenuHeight) {
      position = 'top';
    }

    // 榛樿鏀惧湪涓嬫柟
    else {
      position = 'bottom';
    }

    // ====================================
    // ====================== 鍒ゆ柇鑿滃崟宸﹀彸浣嶇疆
    // ====================================

    // 鍒ゆ柇鍙充晶鏄惁鏀惧緱涓嬭彍鍗昞n    if (windowWidth - itemLeft - itemWidth > submenuWidth) {
      align = 'left';
    }

    // 鍒ゆ柇宸︿晶鏄惁鏀惧緱涓嬭彍鍗昞n    else if (itemLeft > submenuWidth) {
      align = 'right';
    }

    // 榛樿鏀惧湪鍙充晶
    else {
      align = 'left';
    }

    // ===================================
    // ======================== 璁剧疆鑿滃崟浣嶇疆
    // ===================================
    if (position === 'bottom') {
      transformOriginY = '0';
      submenuTop = '0';
    } else if (position === 'top') {
      transformOriginY = '100%';
      submenuTop = -submenuHeight + itemHeight;
    }

    $submenu.css('top', submenuTop + 'px');

    // ===================================
    // ===================== 璁剧疆鑿滃崟瀵归綈鏂瑰紡
    // ===================================
    if (align === 'left') {
      transformOriginX = '0';
      submenuLeft = itemWidth;
    } else if (align === 'right') {
      transformOriginX = '100%';
      submenuLeft = -submenuWidth;
    }

    $submenu.css('left', submenuLeft + 'px');

    // 璁剧疆鑿滃崟鍔ㄧ敾鏂瑰悜
    $submenu.transformOrigin(transformOriginX + ' ' + transformOriginY);
  };

  /**
   * 鎵撳紑瀛愯彍鍗昞n   * @param $submenu
   */
  var openSubMenu = function ($submenu) {
    readjustSubmenu($submenu);

    $submenu
      .addClass('mdui-menu-open')
      .parent('.mdui-menu-item')
      .addClass('mdui-menu-item-active');
  };

  /**
   * 鍏抽棴瀛愯彍鍗曪紝鍙婂叾宓屽鐨勫瓙鑿滃崟
   * @param $submenu
   */
  var closeSubMenu = function ($submenu) {
    // 鍏抽棴瀛愯彍鍗昞n    $submenu
      .removeClass('mdui-menu-open')
      .addClass('mdui-menu-closing')
      .transitionEnd(function () {
        $submenu.removeClass('mdui-menu-closing');
      })

      // 绉婚櫎婵€娲荤姸鎬佺殑鏍峰紡
      .parent('.mdui-menu-item')
      .removeClass('mdui-menu-item-active');

    // 寰幆鍏抽棴宓屽鐨勫瓙鑿滃崟
    $submenu.find('.mdui-menu').each(function () {
      var $subSubmenu = $(this);
      $subSubmenu
        .removeClass('mdui-menu-open')
        .addClass('mdui-menu-closing')
        .transitionEnd(function () {
          $subSubmenu.removeClass('mdui-menu-closing');
        })
        .parent('.mdui-menu-item')
        .removeClass('mdui-menu-item-active');
    });
  };

  /**
   * 鍒囨崲瀛愯彍鍗曠姸鎬乗n   * @param $submenu
   */
  var toggleSubMenu = function ($submenu) {
    if ($submenu.hasClass('mdui-menu-open')) {
      closeSubMenu($submenu);
    } else {
      openSubMenu($submenu);
    }
  };

  /**
   * 缁戝畾瀛愯彍鍗曚簨浠禱n   * @param inst 瀹炰緥
   */
  var bindSubMenuEvent = function (inst) {
    // 鐐瑰嚮鎵撳紑瀛愯彍鍗昞n    inst.$menu.on('click', '.mdui-menu-item', function (e) {
      var $this = $(this);
      var $target = $(e.target);

      // 绂佺敤鐘舵€佽彍鍗曚笉鎿嶄綔
      if ($this.attr('disabled') !== null) {
        return;
      }

      // 娌℃湁鐐瑰嚮鍦ㄥ瓙鑿滃崟鐨勮彍鍗曢」涓婃椂锛屼笉鎿嶄綔锛堢偣鍦ㄤ簡瀛愯彍鍗曠殑绌虹櫧鍖哄煙銆佹垨鍒嗛殧绾夸笂锛塡n      if ($target.is('.mdui-menu') || $target.is('.mdui-divider')) {
        return;
      }

      // 闃绘鍐掓场锛岀偣鍑昏彍鍗曢」鏃跺彧鍦ㄦ渶鍚庝竴绾х殑 mdui-menu-item 涓婄敓鏁堬紝涓嶅悜涓婂啋娉n      if (!$target.parents('.mdui-menu-item').eq(0).is($this)) {
        return;
      }

      // 褰撳墠鑿滃崟鐨勫瓙鑿滃崟
      var $submenu = $this.children('.mdui-menu');

      // 鍏堝叧闂櫎褰撳墠瀛愯彍鍗曞鐨勬墍鏈夊悓绾у瓙鑿滃崟
      $this.parent('.mdui-menu').children('.mdui-menu-item').each(function () {
        var $tmpSubmenu = $(this).children('.mdui-menu');
        if (
          $tmpSubmenu.length &&
          (!$submenu.length || !$tmpSubmenu.is($submenu))
        ) {
          closeSubMenu($tmpSubmenu);
        }
      });

      // 鍒囨崲褰撳墠瀛愯彍鍗昞n      if ($submenu.length) {
        toggleSubMenu($submenu);
      }
    });

    if (inst.options.subMenuTrigger === 'hover') {
      // 涓存椂瀛樺偍 setTimeout 瀵硅薄
      var timeout;

      var timeoutOpen;
      var timeoutClose;

      inst.$menu.on('mouseover mouseout', '.mdui-menu-item', function (e) {
        var $this = $(this);
        var eventType = e.type;
        var $relatedTarget = $(e.relatedTarget);

        // 绂佺敤鐘舵€佺殑鑿滃崟涓嶆搷浣淺n        if ($this.attr('disabled') !== null) {
          return;
        }

        // 鐢 mouseover 妯℃嫙 mouseenter
        if (eventType === 'mouseover') {
          if (!$this.is($relatedTarget) && $.contains($this[0], $relatedTarget[0])) {
            return;
          }
        }

        // 鐢 mouseout 妯℃嫙 mouseleave
        else if (eventType === 'mouseout') {
          if ($this.is($relatedTarget) || $.contains($this[0], $relatedTarget[0])) {
            return;
          }
        }

        // 褰撳墠鑿滃崟椤逛笅鐨勫瓙鑿滃崟锛屾湭蹇呭瓨鍦╘n        var $submenu = $this.children('.mdui-menu');

        // 榧犳爣绉诲叆鑿滃崟椤规椂锛屾樉绀鸿彍鍗曢」涓嬬殑瀛愯彍鍗昞n        if (eventType === 'mouseover') {
          if ($submenu.length) {

            // 褰撳墠瀛愯彍鍗曞噯澶囨墦寮€鏃讹紝濡傛灉褰撳墠瀛愯彍鍗曟鍑嗗鐫€鍏抽棴锛屼笉鐢ㄥ啀鍏抽棴浜哱n            var tmpClose = $submenu.data('timeoutClose.mdui.menu');
            if (tmpClose) {
              clearTimeout(tmpClose);
            }

            // 濡傛灉褰撳墠瀛愯彍鍗曞凡缁忔墦寮€锛屼笉鎿嶄綔
            if ($submenu.hasClass('mdui-menu-open')) {
              return;
            }

            // 褰撳墠瀛愯彍鍗曞噯澶囨墦寮€鏃讹紝鍏朵粬鍑嗗鎵撳紑鐨勫瓙鑿滃崟涓嶇敤鍐嶆墦寮€浜哱n            clearTimeout(timeoutOpen);

            // 鍑嗗鎵撳紑褰撳墠瀛愯彍鍗昞n            timeout = timeoutOpen = setTimeout(function () {
              openSubMenu($submenu);
            }, inst.options.subMenuDelay);

            $submenu.data('timeoutOpen.mdui.menu', timeout);
          }
        }

        // 榧犳爣绉诲嚭鑿滃崟椤规椂锛屽叧闂彍鍗曢」涓嬬殑瀛愯彍鍗昞n        else if (eventType === 'mouseout') {
          if ($submenu.length) {

            // 榧犳爣绉诲嚭鑿滃崟椤规椂锛屽鏋滃綋鍓嶈彍鍗曢」涓嬬殑瀛愯彍鍗曟鍑嗗鎵撳紑锛屼笉鐢ㄥ啀鎵撳紑浜哱n            var tmpOpen = $submenu.data('timeoutOpen.mdui.menu');
            if (tmpOpen) {
              clearTimeout(tmpOpen);
            }

            // 鍑嗗鍏抽棴褰撳墠瀛愯彍鍗昞n            timeout = timeoutClose = setTimeout(function () {
              closeSubMenu($submenu);
            }, inst.options.subMenuDelay);

            $submenu.data('timeoutClose.mdui.menu', timeout);
          }
        }
      });
    }
  };

  /**
   * 鑿滃崟
   * @param anchorSelector 鐐瑰嚮璇ュ厓绱犺Е鍙戣彍鍗昞n   * @param menuSelector 鑿滃崟
   * @param opts 閰嶇疆椤筡n   * @constructor
   */
  function Menu(anchorSelector, menuSelector, opts) {
    var _this = this;

    // 瑙﹀彂鑿滃崟鐨勫厓绱燶n    _this.$anchor = $(anchorSelector).eq(0);
    if (!_this.$anchor.length) {
      return;
    }

    // 宸查€氳繃鑷畾涔夊睘鎬у疄渚嬪寲杩囷紝涓嶅啀閲嶅瀹炰緥鍖朶n    var oldInst = _this.$anchor.data('mdui.menu');
    if (oldInst) {
      return oldInst;
    }

    _this.$menu = $(menuSelector).eq(0);

    // 瑙﹀彂鑿滃崟鐨勫厓绱 鍜 鑿滃崟蹇呴』鏄悓绾х殑鍏冪礌锛屽惁鍒欒彍鍗曞彲鑳戒笉鑳藉畾浣峔n    if (!_this.$anchor.siblings(_this.$menu).length) {
      return;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.state = 'closed';

    // 鏄惁鏄骇鑱旇彍鍗昞n    _this.isCascade = _this.$menu.hasClass('mdui-menu-cascade');

    // covered 鍙傛暟澶勭悊
    if (_this.options.covered === 'auto') {
      _this.isCovered = !_this.isCascade;
    } else {
      _this.isCovered = _this.options.covered;
    }

    // 鐐瑰嚮瑙﹀彂鑿滃崟鍒囨崲
    _this.$anchor.on('click', function () {
      _this.toggle();
    });

    // 鐐瑰嚮鑿滃崟澶栭潰鍖哄煙鍏抽棴鑿滃崟
    $document.on('click touchstart', function (e) {
      var $target = $(e.target);
      if (
        (_this.state === 'opening' || _this.state === 'opened') &&
          !$target.is(_this.$menu) &&
          !$.contains(_this.$menu[0], $target[0]) &&
          !$target.is(_this.$anchor) &&
          !$.contains(_this.$anchor[0], $target[0])
      ) {
        _this.close();
      }
    });

    // 鐐瑰嚮涓嶅惈瀛愯彍鍗曠殑鑿滃崟鏉＄洰鍏抽棴鑿滃崟
    $document.on('click', '.mdui-menu-item', function (e) {
      var $this = $(this);
      if (!$this.find('.mdui-menu').length && $this.attr('disabled') === null) {
        _this.close();
      }
    });

    // 缁戝畾鐐瑰嚮鎴栭紶鏍囩Щ鍏ュ惈瀛愯彍鍗曠殑鏉＄洰鐨勪簨浠禱n    bindSubMenuEvent(_this);

    // 绐楀彛澶у皬鍙樺寲鏃讹紝閲嶆柊璋冩暣鑿滃崟浣嶇疆
    $window.on('resize', $.throttle(function () {
      readjust(_this);
    }, 100));
  }

  /**
   * 鍒囨崲鑿滃崟鐘舵€乗n   */
  Menu.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   * @param inst
   */
  var transitionEnd = function (inst) {
    inst.$menu.removeClass('mdui-menu-closing');

    if (inst.state === 'opening') {
      inst.state = 'opened';
      componentEvent('opened', 'menu', inst, inst.$menu);
    }

    if (inst.state === 'closing') {
      inst.state = 'closed';
      componentEvent('closed', 'menu', inst, inst.$menu);

      // 鍏抽棴鍚庯紝鎭㈠鑿滃崟鏍峰紡鍒伴粯璁ょ姸鎬侊紝骞舵仮澶 fixed 瀹氫綅
      inst.$menu.css({
        top: '',
        left: '',
        width: '',
        position: 'fixed',
      });
    }
  };

  /**
   * 鎵撳紑鑿滃崟
   */
  Menu.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    _this.state = 'opening';
    componentEvent('open', 'menu', _this, _this.$menu);

    // 璋冩暣鑿滃崟浣嶇疆
    readjust(_this);

    _this.$menu

      // 鑿滃崟闅愯棌鐘舵€佷娇鐢ㄤ娇鐢 fixed 瀹氫綅銆俓n      .css('position', _this.options.fixed ? 'fixed' : 'absolute')

      // 鎵撳紑鑿滃崟
      .addClass('mdui-menu-open')

      // 鎵撳紑鍔ㄧ敾瀹屾垚鍚嶾n      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * 鍏抽棴鑿滃崟
   */
  Menu.prototype.close = function () {
    var _this = this;
    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    _this.state = 'closing';
    componentEvent('close', 'menu', _this, _this.$menu);

    // 鑿滃崟寮€濮嬪叧闂椂锛屽叧闂墍鏈夊瓙鑿滃崟
    _this.$menu.find('.mdui-menu').each(function () {
      closeSubMenu($(this));
    });

    _this.$menu
      .removeClass('mdui-menu-open')
      .addClass('mdui-menu-closing')
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  return Menu;
})();

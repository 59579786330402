/**
 * =============================================================================
 * ************   Tab   ************
 * =============================================================================
 */

mdui.Tab = (function () {

  var DEFAULT = {
    trigger: 'click',       // 瑙﹀彂鏂瑰紡 click: 榧犳爣鐐瑰嚮鍒囨崲 hover: 榧犳爣鎮诞鍒囨崲
    //animation: false,       // 鍒囨崲鏃舵槸鍚︽樉绀哄姩鐢籠n    loop: false,            // 涓簍rue鏃讹紝鍦ㄦ渶鍚庝竴涓€夐」鍗℃椂璋冪敤 next() 鏂规硶浼氬洖鍒扮涓€涓€夐」鍗n  };

  // 鍏冪礌鏄惁宸茬鐢╘n  var isDisabled = function ($ele) {
    return $ele[0].disabled || $ele.attr('disabled') !== null;
  };

  /**
   * 閫夐」鍗n   * @param selector
   * @param opts
   * @returns {*}
   * @constructor
   */
  function Tab(selector, opts) {
    var _this = this;

    _this.$tab = $(selector).eq(0);
    if (!_this.$tab.length) {
      return;
    }

    // 宸查€氳繃鑷畾涔夊睘鎬у疄渚嬪寲杩囷紝涓嶅啀閲嶅瀹炰緥鍖朶n    var oldInst = _this.$tab.data('mdui.tab');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.$tabs = _this.$tab.children('a');
    _this.$indicator = $('<div class="mdui-tab-indicator"></div>').appendTo(_this.$tab);
    _this.activeIndex = false; // 涓 false 鏃惰〃绀烘病鏈夋縺娲荤殑閫夐」鍗★紝鎴栦笉瀛樺湪閫夐」鍗n
    // 鏍规嵁 url hash 鑾峰彇榛樿婵€娲荤殑閫夐」鍗n    var hash = location.hash;
    if (hash) {
      _this.$tabs.each(function (i, tab) {
        if ($(tab).attr('href') === hash) {
          _this.activeIndex = i;
          return false;
        }
      });
    }

    // 鍚 mdui-tab-active 鐨勫厓绱犻粯璁ゆ縺娲籠n    if (_this.activeIndex === false) {
      _this.$tabs.each(function (i, tab) {
        if ($(tab).hasClass('mdui-tab-active')) {
          _this.activeIndex = i;
          return false;
        }
      });
    }

    // 瀛樺湪閫夐」鍗℃椂锛岄粯璁ゆ縺娲荤涓€涓€夐」鍗n    if (_this.$tabs.length && _this.activeIndex === false) {
      _this.activeIndex = 0;
    }

    // 璁剧疆婵€娲荤姸鎬侀€夐」鍗n    _this._setActive();

    // 鐩戝惉绐楀彛澶у皬鍙樺寲浜嬩欢锛岃皟鏁存寚绀哄櫒浣嶇疆
    $window.on('resize', $.throttle(function () {
      _this._setIndicatorPosition();
    }, 100));

    // 鐩戝惉鐐瑰嚮閫夐」鍗′簨浠禱n    _this.$tabs.each(function (i, tab) {
      _this._bindTabEvent(tab);
    });
  }

  /**
   * 缁戝畾鍦 Tab 涓婄偣鍑绘垨鎮诞鐨勪簨浠禱n   * @private
   */
  Tab.prototype._bindTabEvent = function (tab) {
    var _this = this;
    var $tab = $(tab);

    // 鐐瑰嚮鎴栭紶鏍囩Щ鍏ヨЕ鍙戠殑浜嬩欢
    var clickEvent = function (e) {
      // 绂佺敤鐘舵€佺殑閫夐」鏃犳硶閫変腑
      if (isDisabled($tab)) {
        e.preventDefault();
        return;
      }

      _this.activeIndex = _this.$tabs.index(tab);
      _this._setActive();
    };

    // 鏃犺 trigger 鏄 click 杩樻槸 hover锛岄兘浼氬搷搴 click 浜嬩欢
    $tab.on('click', clickEvent);

    // trigger 涓 hover 鏃讹紝棰濆鍝嶅簲 mouseenter 浜嬩欢
    if (_this.options.trigger === 'hover') {
      $tab.on('mouseenter', clickEvent);
    }

    $tab.on('click', function (e) {
      // 闃绘閾炬帴鐨勯粯璁ょ偣鍑诲姩浣淺n      if ($tab.attr('href').indexOf('#') === 0) {
        e.preventDefault();
      }
    });
  };

  /**
   * 璁剧疆婵€娲荤姸鎬佺殑閫夐」鍗n   * @private
   */
  Tab.prototype._setActive = function () {
    var _this = this;

    _this.$tabs.each(function (i, tab) {
      var $tab = $(tab);
      var targetId = $tab.attr('href');

      // 璁剧疆閫夐」鍗℃縺娲荤姸鎬乗n      if (i === _this.activeIndex && !isDisabled($tab)) {
        if (!$tab.hasClass('mdui-tab-active')) {
          componentEvent('change', 'tab', _this, _this.$tab, {
            index: _this.activeIndex,
            id: targetId.substr(1),
          });
          componentEvent('show', 'tab', _this, $tab);

          $tab.addClass('mdui-tab-active');
        }

        $(targetId).show();
        _this._setIndicatorPosition();
      } else {
        $tab.removeClass('mdui-tab-active');
        $(targetId).hide();
      }
    });
  };

  /**
   * 璁剧疆閫夐」鍗℃寚绀哄櫒鐨勪綅缃甛n   */
  Tab.prototype._setIndicatorPosition = function () {
    var _this = this;
    var $activeTab;
    var activeTabOffset;

    // 閫夐」鍗℃暟閲忎负 0 鏃讹紝涓嶆樉绀烘寚绀哄櫒
    if (_this.activeIndex === false) {
      _this.$indicator.css({
        left: 0,
        width: 0,
      });

      return;
    }

    $activeTab = _this.$tabs.eq(_this.activeIndex);
    if (isDisabled($activeTab)) {
      return;
    }

    activeTabOffset = $activeTab.offset();
    _this.$indicator.css({
      left: activeTabOffset.left + _this.$tab[0].scrollLeft -
            _this.$tab[0].getBoundingClientRect().left + 'px',
      width: $activeTab.width() + 'px',
    });
  };

  /**
   * 鍒囨崲鍒颁笅涓€涓€夐」鍗n   */
  Tab.prototype.next = function () {
    var _this = this;

    if (_this.activeIndex === false) {
      return;
    }

    if (_this.$tabs.length > _this.activeIndex + 1) {
      _this.activeIndex++;
    } else if (_this.options.loop) {
      _this.activeIndex = 0;
    }

    _this._setActive();
  };

  /**
   * 鍒囨崲鍒颁笂涓€涓€夐」鍗n   */
  Tab.prototype.prev = function () {
    var _this = this;

    if (_this.activeIndex === false) {
      return;
    }

    if (_this.activeIndex > 0) {
      _this.activeIndex--;
    } else if (_this.options.loop) {
      _this.activeIndex = _this.$tabs.length - 1;
    }

    _this._setActive();
  };

  /**
   * 鏄剧ず鎸囧畾搴忓彿鎴栨寚瀹歩d鐨勯€夐」鍗n   * @param index 浠?寮€濮嬬殑搴忓彿锛屾垨浠?寮€澶寸殑id
   */
  Tab.prototype.show = function (index) {
    var _this = this;

    if (_this.activeIndex === false) {
      return;
    }

    if (parseInt(index) === index) {
      _this.activeIndex = index;
    } else {
      _this.$tabs.each(function (i, tab) {
        if (tab.id === index) {
          _this.activeIndex = i;
          return false;
        }
      });
    }

    _this._setActive();
  };

  /**
   * 鍦ㄧ埗鍏冪礌鐨勫搴﹀彉鍖栨椂锛岄渶瑕佽皟鐢ㄨ鏂规硶閲嶆柊璋冩暣鎸囩ず鍣ㄤ綅缃甛n   * 鍦ㄦ坊鍔犳垨鍒犻櫎閫夐」鍗℃椂锛岄渶瑕佽皟鐢ㄨ鏂规硶
   */
  Tab.prototype.handleUpdate = function () {
    var _this = this;

    var $oldTabs = _this.$tabs;               // 鏃х殑 tabs JQ瀵硅薄
    var $newTabs = _this.$tab.children('a');  // 鏂扮殑 tabs JQ瀵硅薄
    var oldTabsEle = $oldTabs.get();          // 鏃 tabs 鐨勫厓绱犳暟缁刓n    var newTabsEle = $newTabs.get();          // 鏂扮殑 tabs 鍏冪礌鏁扮粍

    if (!$newTabs.length) {
      _this.activeIndex = false;
      _this.$tabs = $newTabs;
      _this._setIndicatorPosition();

      return;
    }

    // 閲嶆柊閬嶅巻閫夐」鍗★紝鎵惧嚭鏂板鐨勯€夐」鍗n    $newTabs.each(function (i, tab) {
      // 鏈夋柊澧炵殑閫夐」鍗n      if (oldTabsEle.indexOf(tab) < 0) {
        _this._bindTabEvent(tab);

        if (_this.activeIndex === false) {
          _this.activeIndex = 0;
        } else if (i <= _this.activeIndex) {
          _this.activeIndex++;
        }
      }
    });

    // 鎵惧嚭琚Щ闄ょ殑閫夐」鍗n    $oldTabs.each(function (i, tab) {
      // 鏈夎绉婚櫎鐨勯€夐」鍗n      if (newTabsEle.indexOf(tab) < 0) {

        if (i < _this.activeIndex) {
          _this.activeIndex--;
        } else if (i === _this.activeIndex) {
          _this.activeIndex = 0;
        }
      }
    });

    _this.$tabs = $newTabs;

    _this._setActive();
  };

  return Tab;
})();

/**
 * =============================================================================
 * ************   mdui.alert(text, title, onConfirm, options)   ************
 * ************   mdui.alert(text, onConfirm, options)   ************
 * =============================================================================
 */

mdui.alert = function (text, title, onConfirm, options) {

  // title 鍙傛暟鍙€塡n  if (typeof title === 'function') {
    title = '';
    onConfirm = arguments[1];
    options = arguments[2];
  }

  if (onConfirm === undefined) {
    onConfirm = function () {};
  }

  if (options === undefined) {
    options = {};
  }

  /**
   * 榛樿鍙傛暟
   */
  var DEFAULT = {
    confirmText: 'ok',             // 鎸夐挳涓婄殑鏂囨湰
    history: true,                 // 鐩戝惉 hashchange 浜嬩欢
    modal: false,                  // 鏄惁妯℃€佸寲瀵硅瘽妗嗭紝涓 false 鏃剁偣鍑诲璇濇澶栭潰鍖哄煙鍏抽棴瀵硅瘽妗嗭紝涓 true 鏃朵笉鍏抽棴
    closeOnEsc: true,              // 鎸変笅 esc 鍏抽棴瀵硅瘽妗哱n  };

  options = $.extend({}, DEFAULT, options);

  return mdui.dialog({
    title: title,
    content: text,
    buttons: [
      {
        text: options.confirmText,
        bold: false,
        close: true,
        onClick: onConfirm,
      },
    ],
    cssClass: 'mdui-dialog-alert',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
  });
};

/**
 * =============================================================================
 * ************   娑熸吉   ************
 * =============================================================================
 *
 * Inspired by https://github.com/nolimits4web/Framework7/blob/master/src/js/fast-clicks.js
 * https://github.com/nolimits4web/Framework7/blob/master/LICENSE
 *
 * Inspired by https://github.com/fians/Waves
 */

(function () {

  var Ripple = {

    /**
     * 寤舵椂锛岄伩鍏嶆墜鎸囨粦鍔ㄦ椂涔熻Е鍙戞稛婕紙鍗曚綅锛氭绉掞級
     */
    delay: 200,

    /**
     * 鏄剧ず娑熸吉鍔ㄧ敾
     * @param e
     * @param $ripple
     */
    show: function (e, $ripple) {

      // 榧犳爣鍙抽敭涓嶄骇鐢熸稛婕猏n      if (e.button === 2) {
        return;
      }

      // 鐐瑰嚮浣嶇疆鍧愭爣
      var tmp;
      if ('touches' in e && e.touches.length) {
        tmp = e.touches[0];
      } else {
        tmp = e;
      }

      var touchStartX = tmp.pageX;
      var touchStartY = tmp.pageY;

      // 娑熸吉浣嶇疆
      var offset = $ripple.offset();
      var center = {
        x: touchStartX - offset.left,
        y: touchStartY - offset.top,
      };

      var height = $ripple.innerHeight();
      var width = $ripple.innerWidth();
      var diameter = Math.max(
        Math.pow((Math.pow(height, 2) + Math.pow(width, 2)), 0.5), 48
      );

      // 娑熸吉鎵╂暎鍔ㄧ敾
      var translate =
        'translate3d(' + (-center.x + width / 2) + 'px, ' + (-center.y + height / 2) + 'px, 0) ' +
        'scale(1)';

      // 娑熸吉鐨 DOM 缁撴瀯
      $('<div class="mdui-ripple-wave" style="' +
        'width: ' + diameter + 'px; ' +
        'height: ' + diameter + 'px; ' +
        'margin-top:-' + diameter / 2 + 'px; ' +
        'margin-left:-' + diameter / 2 + 'px; ' +
        'left:' + center.x + 'px; ' +
        'top:' + center.y + 'px;">' +
        '</div>')

        // 缂撳瓨鍔ㄧ敾鏁堟灉
        .data('translate', translate)

        .prependTo($ripple)
        .reflow()
        .transform(translate);
    },

    /**
     * 闅愯棌娑熸吉鍔ㄧ敾
     */
    hide: function (e, element) {
      var $ripple = $(element || this);

      $ripple.children('.mdui-ripple-wave').each(function () {
        removeRipple($(this));
      });

      $ripple.off('touchmove touchend touchcancel mousemove mouseup mouseleave', Ripple.hide);
    },
  };

  /**
   * 闅愯棌骞剁Щ闄ゆ稛婕猏n   * @param $wave
   */
  function removeRipple($wave) {
    if (!$wave.length || $wave.data('isRemoved')) {
      return;
    }

    $wave.data('isRemoved', true);

    var removeTimeout = setTimeout(function () {
      $wave.remove();
    }, 400);

    var translate = $wave.data('translate');

    $wave
      .addClass('mdui-ripple-wave-fill')
      .transform(translate.replace('scale(1)', 'scale(1.01)'))
      .transitionEnd(function () {
        clearTimeout(removeTimeout);

        $wave
          .addClass('mdui-ripple-wave-out')
          .transform(translate.replace('scale(1)', 'scale(1.01)'));

        removeTimeout = setTimeout(function () {
          $wave.remove();
        }, 700);

        setTimeout(function () {
          $wave.transitionEnd(function () {
            clearTimeout(removeTimeout);
            $wave.remove();
          });
        }, 0);
      });
  }

  /**
   * 鏄剧ず娑熸吉锛屽苟缁戝畾 touchend 绛変簨浠禱n   * @param e
   */
  function showRipple(e) {
    if (!TouchHandler.isAllow(e)) {
      return;
    }

    TouchHandler.register(e);

    // Chrome 59 鐐瑰嚮婊氬姩鏉℃椂锛屼細鍦 document 涓婅Е鍙戜簨浠禱n    if (e.target === document) {
      return;
    }

    var $ripple;
    var $target = $(e.target);

    // 鑾峰彇鍚 .mdui-ripple 绫荤殑鍏冪礌
    if ($target.hasClass('mdui-ripple')) {
      $ripple = $target;
    } else {
      $ripple = $target.parents('.mdui-ripple').eq(0);
    }

    if ($ripple.length) {

      // 绂佺敤鐘舵€佺殑鍏冪礌涓婁笉浜х敓娑熸吉鏁堟灉
      if ($ripple[0].disabled || $ripple.attr('disabled') !== null) {
        return;
      }

      if (e.type === 'touchstart') {
        var hidden = false;

        // toucstart 瑙﹀彂鎸囧畾鏃堕棿鍚庡紑濮嬫稛婕姩鐢籠n        var timer = setTimeout(function () {
          timer = null;
          Ripple.show(e, $ripple);
        }, Ripple.delay);

        var hideRipple = function (hideEvent) {
          // 濡傛灉鎵嬫寚娌℃湁绉诲姩锛屼笖娑熸吉鍔ㄧ敾杩樻病鏈夊紑濮嬶紝鍒欏紑濮嬫稛婕姩鐢籠n          if (timer) {
            clearTimeout(timer);
            timer = null;
            Ripple.show(e, $ripple);
          }

          if (!hidden) {
            hidden = true;
            Ripple.hide(hideEvent, $ripple);
          }
        };

        // 鎵嬫寚绉诲姩鍚庯紝绉婚櫎娑熸吉鍔ㄧ敾
        var touchMove = function (moveEvent) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }

          hideRipple(moveEvent);
        };

        $ripple
          .on('touchmove', touchMove)
          .on('touchend touchcancel', hideRipple);

      } else {
        Ripple.show(e, $ripple);
        $ripple.on('touchmove touchend touchcancel mousemove mouseup mouseleave', Ripple.hide);
      }
    }
  }

  // 鍒濆鍖栫粦瀹氱殑浜嬩欢
  $document
    .on(TouchHandler.start, showRipple)
    .on(TouchHandler.unlock, TouchHandler.register);
})();

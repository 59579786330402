/**
 * =============================================================================
 * ************   mdui.prompt(label, title, onConfirm, onCancel, options)   ************
 * ************   mdui.prompt(label, onConfirm, onCancel, options)          ************
 * =============================================================================
 */

mdui.prompt = function (label, title, onConfirm, onCancel, options) {

  // title 鍙傛暟鍙€塡n  if (typeof title === 'function') {
    title = '';
    onConfirm = arguments[1];
    onCancel = arguments[2];
    options = arguments[3];
  }

  if (onConfirm === undefined) {
    onConfirm = function () {};
  }

  if (onCancel === undefined) {
    onCancel = function () {};
  }

  if (options === undefined) {
    options = {};
  }

  /**
   * 榛樿鍙傛暟
   */
  var DEFAULT = {
    confirmText: 'ok',        // 纭鎸夐挳鐨勬枃鏈琝n    cancelText: 'cancel',     // 鍙栨秷鎸夐挳鐨勬枃鏈琝n    history: true,            // 鐩戝惉 hashchange 浜嬩欢
    modal: false,             // 鏄惁妯℃€佸寲瀵硅瘽妗嗭紝涓 false 鏃剁偣鍑诲璇濇澶栭潰鍖哄煙鍏抽棴瀵硅瘽妗嗭紝涓 true 鏃朵笉鍏抽棴
    closeOnEsc: true,         // 鎸変笅 esc 鍏抽棴瀵硅瘽妗哱n    type: 'text',             // 杈撳叆妗嗙被鍨嬶紝text: 鍗曡鏂囨湰妗 textarea: 澶氳鏂囨湰妗哱n    maxlength: '',            // 鏈€澶ц緭鍏ュ瓧绗︽暟
    defaultValue: '',         // 杈撳叆妗嗕腑鐨勯粯璁ゆ枃鏈琝n    confirmOnEnter: false,    // 鎸変笅 enter 纭杈撳叆鍐呭
  };

  options = $.extend({}, DEFAULT, options);

  var content =
    '<div class="mdui-textfield">' +
      (label ? '<label class="mdui-textfield-label">' + label + '</label>' : '') +
      (options.type === 'text' ?
        '<input class="mdui-textfield-input" type="text" ' +
          'value="' + options.defaultValue + '" ' +
          (options.maxlength ? ('maxlength="' + options.maxlength + '"') : '') + '/>' :
        '') +
      (options.type === 'textarea' ?
        '<textarea class="mdui-textfield-input" ' +
          (options.maxlength ? ('maxlength="' + options.maxlength + '"') : '') + '>' +
            options.defaultValue +
        '</textarea>' :
        '') +
    '</div>';

  var onCancelClick = onCancel;
  if (typeof onCancel === 'function') {
    onCancelClick = function (inst) {
      var value = inst.$dialog.find('.mdui-textfield-input').val();
      onCancel(value, inst);
    }
  }

  var onConfirmClick = onConfirm;
  if (typeof onConfirm === 'function') {
    onConfirmClick = function (inst) {
      var value = inst.$dialog.find('.mdui-textfield-input').val();
      onConfirm(value, inst);
    }
  }

  return mdui.dialog({
    title: title,
    content: content,
    buttons: [
      {
        text: options.cancelText,
        bold: false,
        close: true,
        onClick: onCancelClick,
      },
      {
        text: options.confirmText,
        bold: false,
        close: true,
        onClick: onConfirmClick,
      },
    ],
    cssClass: 'mdui-dialog-prompt',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
    onOpen: function (inst) {

      // 鍒濆鍖栬緭鍏ユ
      var $input = inst.$dialog.find('.mdui-textfield-input');
      mdui.updateTextFields($input);

      // 鑱氱劍鍒拌緭鍏ユ
      $input[0].focus();

      // 鎹曟崏鏂囨湰妗嗗洖杞﹂敭锛屽湪鍗曡鏂囨湰妗嗙殑鎯呭喌涓嬭Е鍙戝洖璋僜n      if (options.type === 'text' && options.confirmOnEnter === true) {
        $input.on('keydown', function (event) {
          if (event.keyCode === 13) {
            var value = inst.$dialog.find('.mdui-textfield-input').val();
            onConfirm(value, inst);
            inst.close();
          }
        });
      }

      // 濡傛灉鏄琛岃緭鍏ユ锛岀洃鍚緭鍏ユ鐨 input 浜嬩欢锛屾洿鏂板璇濇楂樺害
      if (options.type === 'textarea') {
        $input.on('input', function () {
          inst.handleUpdate();
        });
      }

      // 鏈夊瓧绗︽暟闄愬埗鏃讹紝鍔犺浇瀹屾枃鏈鍚 DOM 浼氬彉鍖栵紝闇€瑕佹洿鏂板璇濇楂樺害
      if (options.maxlength) {
        inst.handleUpdate();
      }
    },
  });

};

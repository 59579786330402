/**
 * =============================================================================
 * ************   Dialog 瀵硅瘽妗   ************
 * =============================================================================
 */

mdui.Dialog = (function () {

  /**
   * 榛樿鍙傛暟
   */
  var DEFAULT = {
    history: true,                // 鐩戝惉 hashchange 浜嬩欢
    overlay: true,                // 鎵撳紑瀵硅瘽妗嗘椂鏄惁鏄剧ず閬僵
    modal: false,                 // 鏄惁妯℃€佸寲瀵硅瘽妗嗭紝涓 false 鏃剁偣鍑诲璇濇澶栭潰鍖哄煙鍏抽棴瀵硅瘽妗嗭紝涓 true 鏃朵笉鍏抽棴
    closeOnEsc: true,             // 鎸変笅 esc 鍏抽棴瀵硅瘽妗哱n    closeOnCancel: true,          // 鎸変笅鍙栨秷鎸夐挳鏃跺叧闂璇濇
    closeOnConfirm: true,         // 鎸変笅纭鎸夐挳鏃跺叧闂璇濇
    destroyOnClosed: false,        // 鍏抽棴鍚庨攢姣乗n  };

  /**
   * 閬僵灞傚厓绱燶n   */
  var $overlay;

  /**
   * 绐楀彛鏄惁宸查攣瀹歕n   */
  var isLockScreen;

  /**
   * 褰撳墠瀵硅瘽妗嗗疄渚媆n   */
  var currentInst;

  /**
   * 闃熷垪鍚峔n   */
  var queueName = '__md_dialog';

  /**
   * 绐楀彛瀹藉害鍙樺寲锛屾垨瀵硅瘽妗嗗唴瀹瑰彉鍖栨椂锛岃皟鏁村璇濇浣嶇疆鍜屽璇濇鍐呯殑婊氬姩鏉n   */
  var readjust = function () {
    if (!currentInst) {
      return;
    }

    var $dialog = currentInst.$dialog;

    var $dialogTitle = $dialog.children('.mdui-dialog-title');
    var $dialogContent = $dialog.children('.mdui-dialog-content');
    var $dialogActions = $dialog.children('.mdui-dialog-actions');

    // 璋冩暣 dialog 鐨 top 鍜 height 鍊糪n    $dialog.height('');
    $dialogContent.height('');

    var dialogHeight = $dialog.height();
    $dialog.css({
      top: (($window.height() - dialogHeight) / 2) + 'px',
      height: dialogHeight + 'px',
    });

    // 璋冩暣 mdui-dialog-content 鐨勯珮搴n    $dialogContent.height(dialogHeight - ($dialogTitle.height() || 0) - ($dialogActions.height() || 0));
  };

  /**
   * hashchange 浜嬩欢瑙﹀彂鏃跺叧闂璇濇
   */
  var hashchangeEvent = function () {
    if (location.hash.substring(1).indexOf('mdui-dialog') < 0) {
      currentInst.close(true);
    }
  };

  /**
   * 鐐瑰嚮閬僵灞傚叧闂璇濇
   * @param e
   */
  var overlayClick = function (e) {
    if ($(e.target).hasClass('mdui-overlay') && currentInst) {
      currentInst.close();
    }
  };

  /**
   * 瀵硅瘽妗嗗疄渚媆n   * @param selector 閫夋嫨鍣ㄦ垨 HTML 瀛楃涓叉垨 DOM 鍏冪礌
   * @param opts
   * @constructor
   */
  function Dialog(selector, opts) {
    var _this = this;

    // 瀵硅瘽妗嗗厓绱燶n    _this.$dialog = $(selector).eq(0);
    if (!_this.$dialog.length) {
      return;
    }

    // 宸查€氳繃 data 灞炴€у疄渚嬪寲杩囷紝涓嶅啀閲嶅瀹炰緥鍖朶n    var oldInst = _this.$dialog.data('mdui.dialog');
    if (oldInst) {
      return oldInst;
    }

    // 濡傛灉瀵硅瘽妗嗗厓绱犳病鏈夊湪褰撳墠鏂囨。涓紝鍒欓渶瑕佹坊鍔燶n    if (!$.contains(document.body, _this.$dialog[0])) {
      _this.append = true;
      $('body').append(_this.$dialog);
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.state = 'closed';

    // 缁戝畾鍙栨秷鎸夐挳浜嬩欢
    _this.$dialog.find('[mdui-dialog-cancel]').each(function () {
      $(this).on('click', function () {
        componentEvent('cancel', 'dialog', _this, _this.$dialog);
        if (_this.options.closeOnCancel) {
          _this.close();
        }
      });
    });

    // 缁戝畾纭鎸夐挳浜嬩欢
    _this.$dialog.find('[mdui-dialog-confirm]').each(function () {
      $(this).on('click', function () {
        componentEvent('confirm', 'dialog', _this, _this.$dialog);
        if (_this.options.closeOnConfirm) {
          _this.close();
        }
      });
    });

    // 缁戝畾鍏抽棴鎸夐挳浜嬩欢
    _this.$dialog.find('[mdui-dialog-close]').each(function () {
      $(this).on('click', function () {
        _this.close();
      });
    });
  }

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   * @param inst
   */
  var transitionEnd = function (inst) {
    if (inst.$dialog.hasClass('mdui-dialog-open')) {
      inst.state = 'opened';
      componentEvent('opened', 'dialog', inst, inst.$dialog);
    } else {
      inst.state = 'closed';
      componentEvent('closed', 'dialog', inst, inst.$dialog);

      inst.$dialog.hide();

      // 鎵€鏈夊璇濇閮藉叧闂紝涓斿綋鍓嶆病鏈夋墦寮€鐨勫璇濇鏃讹紝瑙ｉ攣灞忓箷
      if (queue.queue(queueName).length === 0 && !currentInst && isLockScreen) {
        $.unlockScreen();
        isLockScreen = false;
      }

      $window.off('resize', $.throttle(function () {
        readjust();
      }, 100));

      if (inst.options.destroyOnClosed) {
        inst.destroy();
      }
    }
  };

  /**
   * 鎵撳紑鎸囧畾瀵硅瘽妗哱n   * @private
   */
  Dialog.prototype._doOpen = function () {
    var _this = this;

    currentInst = _this;

    if (!isLockScreen) {
      $.lockScreen();
      isLockScreen = true;
    }

    _this.$dialog.show();

    readjust();
    $window.on('resize', $.throttle(function () {
      readjust();
    }, 100));

    // 鎵撳紑娑堟伅妗哱n    _this.state = 'opening';
    componentEvent('open', 'dialog', _this, _this.$dialog);

    _this.$dialog
      .addClass('mdui-dialog-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });

    // 涓嶅瓨鍦ㄩ伄缃╁眰鍏冪礌鏃讹紝娣诲姞閬僵灞俓n    if (!$overlay) {
      $overlay = $.showOverlay(5100);
    }

    $overlay

      // 鐐瑰嚮閬僵灞傛椂鏄惁鍏抽棴瀵硅瘽妗哱n      [_this.options.modal ? 'off' : 'on']('click', overlayClick)

      // 鏄惁鏄剧ず閬僵灞傦紝涓嶆樉绀烘椂锛屾妸閬僵灞傝儗鏅€忔槑
      .css('opacity', _this.options.overlay ? '' : 0);

    if (_this.options.history) {
      // 濡傛灉 hash 涓師鏉ュ氨鏈 mdui-dialog锛屽厛鍒犻櫎锛岄伩鍏嶅悗閫€鍘嗗彶绾綍鍚庝粛鐒舵湁 mdui-dialog 瀵艰嚧鏃犳硶鍏抽棴
      // 鍖呮嫭 mdui-dialog 鍜 &mdui-dialog 鍜 ?mdui-dialog
      var hash = location.hash.substring(1);
      if (hash.indexOf('mdui-dialog') > -1) {
        hash = hash.replace(/[&?]?mdui-dialog/g, '');
      }

      // 鍚庨€€鎸夐挳鍏抽棴瀵硅瘽妗哱n      if (hash) {
        location.hash = hash + (hash.indexOf('?') > -1 ? '&' : '?') + 'mdui-dialog';
      } else {
        location.hash = 'mdui-dialog';
      }

      $window.on('hashchange', hashchangeEvent);
    }
  };

  /**
   * 鎵撳紑瀵硅瘽妗哱n   */
  Dialog.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    // 濡傛灉褰撳墠鏈夋鍦ㄦ墦寮€鎴栧凡缁忔墦寮€鐨勫璇濇,鎴栭槦鍒椾笉涓虹┖锛屽垯鍏堝姞鍏ラ槦鍒楋紝绛夋棫瀵硅瘽妗嗗紑濮嬪叧闂椂鍐嶆墦寮€
    if (
      (currentInst && (currentInst.state === 'opening' || currentInst.state === 'opened')) ||
      queue.queue(queueName).length
    ) {
      queue.queue(queueName, function () {
        _this._doOpen();
      });

      return;
    }

    _this._doOpen();
  };

  /**
   * 鍏抽棴瀵硅瘽妗哱n   */
  Dialog.prototype.close = function () {
    var _this = this;
    var _arguments = arguments;

    // setTimeout 鐨勪綔鐢ㄦ槸锛歕n    // 褰撳悓鏃跺叧闂竴涓璇濇锛屽苟鎵撳紑鍙︿竴涓璇濇鏃讹紝浣挎墦寮€瀵硅瘽妗嗙殑鎿嶄綔鍏堟墽琛岋紝浠ヤ娇闇€瑕佹墦寮€鐨勫璇濇鍏堝姞鍏ラ槦鍒梊n    setTimeout(function () {
      if (_this.state === 'closing' || _this.state === 'closed') {
        return;
      }

      currentInst = null;

      _this.state = 'closing';
      componentEvent('close', 'dialog', _this, _this.$dialog);

      // 鎵€鏈夊璇濇閮藉叧闂紝涓斿綋鍓嶆病鏈夋墦寮€鐨勫璇濇鏃讹紝闅愯棌閬僵
      if (queue.queue(queueName).length === 0 && $overlay) {
        $.hideOverlay();
        $overlay = null;
      }

      _this.$dialog
        .removeClass('mdui-dialog-open')
        .transitionEnd(function () {
          transitionEnd(_this);
        });

      if (_this.options.history && queue.queue(queueName).length === 0) {
        // 鏄惁闇€瑕佸悗閫€鍘嗗彶绾綍锛岄粯璁や负 false銆俓n        // 涓 false 鏃舵槸閫氳繃 js 鍏抽棴锛岄渶瑕佸悗閫€涓€涓巻鍙茶褰昞n        // 涓 true 鏃舵槸閫氳繃鍚庨€€鎸夐挳鍏抽棴锛屼笉闇€瑕佸悗閫€鍘嗗彶璁板綍
        if (!_arguments[0]) {
          window.history.back();
        }

        $window.off('hashchange', hashchangeEvent);
      }

      // 鍏抽棴鏃у璇濇锛屾墦寮€鏂板璇濇銆俓n      // 鍔犱竴鐐瑰欢杩燂紝浠呬粎涓轰簡瑙嗚鏁堟灉鏇村ソ銆備笉鍔犲欢鏃朵篃涓嶅奖鍝嶅姛鑳絓n      setTimeout(function () {
        queue.dequeue(queueName);
      }, 100);
    }, 0);
  };

  /**
   * 鍒囨崲瀵硅瘽妗嗘墦寮€/鍏抽棴鐘舵€乗n   */
  Dialog.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * 鑾峰彇瀵硅瘽妗嗙姸鎬乗n   * @returns {'opening'|'opened'|'closing'|'closed'}
   */
  Dialog.prototype.getState = function () {
    return this.state;
  };

  /**
   * 閿€姣佸璇濇
   */
  Dialog.prototype.destroy = function () {
    var _this = this;

    if (_this.append) {
      _this.$dialog.remove();
    }

    _this.$dialog.removeData('mdui.dialog');

    if (queue.queue(queueName).length === 0 && !currentInst) {
      if ($overlay) {
        $.hideOverlay();
        $overlay = null;
      }

      if (isLockScreen) {
        $.unlockScreen();
        isLockScreen = false;
      }
    }
  };

  /**
   * 瀵硅瘽妗嗗唴瀹瑰彉鍖栨椂锛岄渶瑕佽皟鐢ㄨ鏂规硶鏉ヨ皟鏁村璇濇浣嶇疆鍜屾粴鍔ㄦ潯楂樺害
   */
  Dialog.prototype.handleUpdate = function () {
    readjust();
  };

  // esc 鎸変笅鏃跺叧闂璇濇
  $document.on('keydown', function (e) {
    if (
      currentInst &&
      currentInst.options.closeOnEsc &&
      currentInst.state === 'opened' &&
      e.keyCode === 27
    ) {
      currentInst.close();
    }
  });

  return Dialog;

})();

/**
 * =============================================================================
 * ************   瀹氫箟鍏ㄥ眬鍙橀噺   ************
 * =============================================================================
 */

var $document = $(document);
var $window = $(window);

/**
 * 闃熷垪 -- 褰撳墠闃熷垪鐨 api 鍜 jquery 涓嶄竴鏍凤紝鎵€浠ヤ笉鎵撳寘杩 mdui.JQ 閲孿n */
var queue = {};
(function () {
  var queueData = [];

  /**
   * 鍐欏叆闃熷垪
   * @param queueName 瀵瑰垪鍚峔n   * @param func 鍑芥暟鍚嶏紝璇ュ弬鏁颁负绌烘椂锛岃繑鍥炴墍鏈夐槦鍒梊n   */
  queue.queue = function (queueName, func) {
    if (queueData[queueName] === undefined) {
      queueData[queueName] = [];
    }

    if (func === undefined) {
      return queueData[queueName];
    }

    queueData[queueName].push(func);
  };

  /**
   * 浠庨槦鍒椾腑绉婚櫎绗竴涓嚱鏁帮紝骞舵墽琛岃鍑芥暟
   * @param queueName
   */
  queue.dequeue = function (queueName) {
    if (queueData[queueName] !== undefined && queueData[queueName].length) {
      (queueData[queueName].shift())();
    }
  };

})();

/**
 * touch 浜嬩欢鍚庣殑 500ms 鍐呯鐢 mousedown 浜嬩欢
 *
 * 涓嶆敮鎸佽Е鎺х殑灞忓箷涓婁簨浠堕『搴忎负 mousedown -> mouseup -> click
 * 鏀寔瑙︽帶鐨勫睆骞曚笂浜嬩欢椤哄簭涓 touchstart -> touchend -> mousedown -> mouseup -> click
 */
var TouchHandler = {
  touches: 0,

  /**
   * 璇ヤ簨浠舵槸鍚﹁鍏佽
   * 鍦ㄦ墽琛屼簨浠跺墠璋冪敤璇ユ柟娉曞垽鏂簨浠舵槸鍚﹀彲浠ユ墽琛孿n   * @param e
   * @returns {boolean}
   */
  isAllow: function (e) {
    var allow = true;

    if (
      TouchHandler.touches &&
      [
        'mousedown',
        'mouseup',
        'mousemove',
        'click',
        'mouseover',
        'mouseout',
        'mouseenter',
        'mouseleave',
      ].indexOf(e.type) > -1
    ) {
      // 瑙﹀彂浜 touch 浜嬩欢鍚庨樆姝㈤紶鏍囦簨浠禱n      allow = false;
    }

    return allow;
  },

  /**
   * 鍦 touchstart 鍜 touchmove銆乼ouchend銆乼ouchcancel 浜嬩欢涓皟鐢ㄨ鏂规硶娉ㄥ唽浜嬩欢
   * @param e
   */
  register: function (e) {
    if (e.type === 'touchstart') {
      // 瑙﹀彂浜 touch 浜嬩欢
      TouchHandler.touches += 1;
    } else if (['touchmove', 'touchend', 'touchcancel'].indexOf(e.type) > -1) {
      // touch 浜嬩欢缁撴潫 500ms 鍚庤В闄ゅ榧犳爣浜嬩欢鐨勯樆姝n      setTimeout(function () {
        if (TouchHandler.touches) {
          TouchHandler.touches -= 1;
        }
      }, 500);
    }
  },

  start: 'touchstart mousedown',
  move: 'touchmove mousemove',
  end: 'touchend mouseup',
  cancel: 'touchcancel mouseleave',
  unlock: 'touchend touchmove touchcancel',
};

// 娴嬭瘯浜嬩欢
// 鍦ㄦ瘡涓€涓簨浠朵腑閮戒娇鐢 TouchHandler.isAllow(e) 鍒ゆ柇浜嬩欢鏄惁鍙墽琛孿n// 鍦 touchstart 鍜 touchmove銆乼ouchend銆乼ouchcancel
// (function () {
//
//   $document
//     .on(TouchHandler.start, function (e) {
//       if (!TouchHandler.isAllow(e)) {
//         return;
//       }
//       TouchHandler.register(e);
//       console.log(e.type);
//     })
//     .on(TouchHandler.move, function (e) {
//       if (!TouchHandler.isAllow(e)) {
//         return;
//       }
//       console.log(e.type);
//     })
//     .on(TouchHandler.end, function (e) {
//       if (!TouchHandler.isAllow(e)) {
//         return;
//       }
//       console.log(e.type);
//     })
//     .on(TouchHandler.unlock, TouchHandler.register);
// })();

$(function () {
  // 閬垮厤椤甸潰鍔犺浇瀹屽悗鐩存帴鎵цcss鍔ㄧ敾
  // https://css-tricks.com/transitions-only-after-page-load/

  setTimeout(function () {
    $('body').addClass('mdui-loaded');
  }, 0);
});

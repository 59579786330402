/**
 * =============================================================================
 * ************   渚 Collapse銆 Panel 璋冪敤鐨勬姌鍙犲唴瀹瑰潡鎻掍欢   ************
 * =============================================================================
 */
var CollapsePrivate = (function () {

  /**
   * 榛樿鍙傛暟
   */
  var DEFAULT = {
    accordion: false,                             // 鏄惁浣跨敤鎵嬮鐞存晥鏋淺n  };

  /**
   * 鎶樺彔鍐呭鍧梊n   * @param selector
   * @param opts
   * @param namespace
   * @constructor
   */
  function Collapse(selector, opts, namespace) {
    var _this = this;

    // 鍛藉悕绌洪棿
    _this.ns = namespace;

    // 绫诲悕
    var classpPefix = 'mdui-' + _this.ns + '-item';
    _this.class_item = classpPefix;
    _this.class_item_open = classpPefix + '-open';
    _this.class_header = classpPefix + '-header';
    _this.class_body = classpPefix + '-body';

    // 鎶樺彔闈㈡澘鍏冪礌
    _this.$collapse = $(selector).eq(0);
    if (!_this.$collapse.length) {
      return;
    }

    // 宸查€氳繃鑷畾涔夊睘鎬у疄渚嬪寲杩囷紝涓嶅啀閲嶅瀹炰緥鍖朶n    var oldInst = _this.$collapse.data('mdui.' + _this.ns);
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));

    _this.$collapse.on('click', '.' + _this.class_header, function () {
      var $item = $(this).parent('.' + _this.class_item);
      if (_this.$collapse.children($item).length) {
        _this.toggle($item);
      }
    });

    // 缁戝畾鍏抽棴鎸夐挳
    _this.$collapse.on('click', '[mdui-' + _this.ns + '-item-close]', function () {
      var $item = $(this).parents('.' + _this.class_item).eq(0);
      if (_this._isOpen($item)) {
        _this.close($item);
      }
    });
  }

  /**
   * 鎸囧畾 item 鏄惁澶勪簬鎵撳紑鐘舵€乗n   * @param $item
   * @returns {boolean}
   * @private
   */
  Collapse.prototype._isOpen = function ($item) {
    return $item.hasClass(this.class_item_open);
  };

  /**
   * 鑾峰彇鎸囧畾 item
   * @param item
   * @returns {*}
   * @private
   */
  Collapse.prototype._getItem = function (item) {
    var _this = this;

    if (parseInt(item) === item) {
      // item 鏄储寮曞彿
      return _this.$collapse.children('.' + _this.class_item).eq(item);
    }

    return $(item).eq(0);
  };

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   * @param inst
   * @param $content
   * @param $item
   */
  var transitionEnd = function (inst, $content, $item) {
    if (inst._isOpen($item)) {
      $content
        .transition(0)
        .height('auto')
        .reflow()
        .transition('');

      componentEvent('opened', inst.ns, inst, $item[0]);
    } else {
      $content.height('');

      componentEvent('closed', inst.ns, inst, $item[0]);
    }
  };

  /**
   * 鎵撳紑鎸囧畾闈㈡澘椤筡n   * @param item 闈㈡澘椤圭殑绱㈠紩鍙锋垨 DOM 鍏冪礌鎴 CSS 閫夋嫨鍣╘n   */
  Collapse.prototype.open = function (item) {
    var _this = this;
    var $item = _this._getItem(item);

    if (_this._isOpen($item)) {
      return;
    }

    // 鍏抽棴鍏朵粬椤筡n    if (_this.options.accordion) {
      _this.$collapse.children('.' + _this.class_item_open).each(function () {
        var $tmpItem = $(this);

        if ($tmpItem !== $item) {
          _this.close($tmpItem);
        }
      });
    }

    var $content = $item.children('.' + _this.class_body);

    $content
      .height($content[0].scrollHeight)
      .transitionEnd(function () {
        transitionEnd(_this, $content, $item);
      });

    componentEvent('open', _this.ns, _this, $item[0]);

    $item.addClass(_this.class_item_open);
  };

  /**
   * 鍏抽棴鎸囧畾椤筡n   * @param item 闈㈡澘椤圭殑绱㈠紩鍙锋垨 DOM 鍏冪礌鎴 CSS 閫夋嫨鍣╘n   */
  Collapse.prototype.close = function (item) {
    var _this = this;
    var $item = _this._getItem(item);

    if (!_this._isOpen($item)) {
      return;
    }

    var $content = $item.children('.' + _this.class_body);

    componentEvent('close', _this.ns, _this, $item[0]);

    $item.removeClass(_this.class_item_open);

    $content
      .transition(0)
      .height($content[0].scrollHeight)
      .reflow()
      .transition('')
      .height('')
      .transitionEnd(function () {
        transitionEnd(_this, $content, $item);
      });
  };

  /**
   * 鍒囨崲鎸囧畾椤圭殑鐘舵€乗n   * @param item 闈㈡澘椤圭殑绱㈠紩鍙锋垨 DOM 鍏冪礌鎴 CSS 閫夋嫨鍣ㄦ垨 JQ 瀵硅薄
   */
  Collapse.prototype.toggle = function (item) {
    var _this = this;
    var $item = _this._getItem(item);

    if (_this._isOpen($item)) {
      _this.close($item);
    } else {
      _this.open($item);
    }
  };

  /**
   * 鎵撳紑鎵€鏈夐」
   */
  Collapse.prototype.openAll = function () {
    var _this = this;

    _this.$collapse.children('.' + _this.class_item).each(function () {
      var $tmpItem = $(this);

      if (!_this._isOpen($tmpItem)) {
        _this.open($tmpItem);
      }
    });
  };

  /**
   * 鍏抽棴鎵€鏈夐」
   */
  Collapse.prototype.closeAll = function () {
    var _this = this;

    _this.$collapse.children('.' + _this.class_item).each(function () {
      var $tmpItem = $(this);

      if (_this._isOpen($tmpItem)) {
        _this.close($tmpItem);
      }
    });
  };

  return Collapse;
})();

/**
 * =============================================================================
 * ************   Collapse 鎶樺彔鍐呭鍧楁彃浠   ************
 * =============================================================================
 */
mdui.Collapse = (function () {

  function Collapse(selector, opts) {
    return new CollapsePrivate(selector, opts, 'collapse');
  }

  return Collapse;
})();

/**
 * JQ 1.0.0 (https://github.com/zdhxiong/mdui.JQ#readme)
 * Copyright 2018-2018 zdhxiong
 * Licensed under MIT
 */
var $ = (function () {
  'use strict';

  var JQ = function JQ(arr) {
    var self = this;

    for (var i = 0; i < arr.length; i += 1) {
      self[i] = arr[i];
    }

    self.length = arr.length;

    return this;
  };

  function $(selector) {
    var arr = [];

    if (!selector) {
      return new JQ(arr);
    }

    if (selector instanceof JQ) {
      return selector;
    }

    if (typeof selector === 'string') {
      var html = selector.trim();

      if (html[0] === '<' && html[html.length - 1] === '>') {
        // 鍒涘缓 HTML 瀛楃涓瞈n        var toCreate = 'div';

        if (html.indexOf('<li') === 0) {
          toCreate = 'ul';
        }

        if (html.indexOf('<tr') === 0) {
          toCreate = 'tbody';
        }

        if (html.indexOf('<td') === 0 || html.indexOf('<th') === 0) {
          toCreate = 'tr';
        }

        if (html.indexOf('<tbody') === 0) {
          toCreate = 'table';
        }

        if (html.indexOf('<option') === 0) {
          toCreate = 'select';
        }

        var tempParent = document.createElement(toCreate);
        tempParent.innerHTML = html;

        for (var i = 0; i < tempParent.childNodes.length; i += 1) {
          arr.push(tempParent.childNodes[i]);
        }
      } else {
        // 閫夋嫨鍣╘n        var elems = selector[0] === '#' && !selector.match(/[ .<>:~]/)
          ? [document.getElementById(selector.slice(1))]
          : document.querySelectorAll(selector);

        for (var i$1 = 0; i$1 < elems.length; i$1 += 1) {
          if (elems[i$1]) {
            arr.push(elems[i$1]);
          }
        }
      }
    } else if (typeof selector === 'function') {
      // function
      return $(document).ready(selector);
    } else if (selector.nodeType || selector === window || selector === document) {
      // Node
      arr.push(selector);
    } else if (selector.length > 0 && selector[0].nodeType) {
      // NodeList
      for (var i$2 = 0; i$2 < selector.length; i$2 += 1) {
        arr.push(selector[i$2]);
      }
    }

    return new JQ(arr);
  }

  $.fn = JQ.prototype;

  function extend() {
    var this$1 = this;
    var args = [], len = arguments.length;
    while ( len-- ) args[ len ] = arguments[ len ];

    if (!args.length) {
      return this;
    }

    // $.extend(obj)
    if (args.length === 1) {
      Object.keys(args[0]).forEach(function (prop) {
        this$1[prop] = args[0][prop];
      });

      return this;
    }

    // $.extend({}, defaults[, obj])
    var target = args.shift();

    var loop = function ( i ) {
      Object.keys(args[i]).forEach(function (prop) {
        target[prop] = args[i][prop];
      });
    };

    for (var i = 0; i < args.length; i += 1) loop( i );

    return target;
  }

  $.fn.extend = extend;
  $.extend = extend;

  /**
   * 鍒ゆ柇涓€涓妭鐐瑰悕
   * @param ele
   * @param name
   * @returns {boolean}
   */
  function isNodeName(ele, name) {
    return ele.nodeName && ele.nodeName.toLowerCase() === name.toLowerCase();
  }

  /**
   * 闄ゅ幓 null 鍚庣殑 object 绫诲瀷
   * @param obj
   * @returns {*|boolean}
   */
  function isObjectLike(obj) {
    return typeof obj === 'object' && obj !== null;
  }

  function isFunction(fn) {
    return typeof fn === 'function';
  }

  function isString(obj) {
    return typeof obj === 'string';
  }

  function isWindow(win) {
    return win && win === win.window;
  }

  function isDocument(doc) {
    return doc && doc.nodeType === doc.DOCUMENT_NODE;
  }

  function isArrayLike(obj) {
    return typeof obj.length === 'number';
  }

  /**
   * 寰幆鏁扮粍鎴栧璞n   * @param obj
   * @param callback
   * @returns {*}
   */
  function each(obj, callback) {
    if (isArrayLike(obj)) {
      for (var i = 0; i < obj.length; i += 1) {
        if (callback.call(obj[i], i, obj[i]) === false) {
          return obj;
        }
      }
    } else {
      var keys = Object.keys(obj);
      for (var i$1 = 0; i$1 < keys.length; i$1 += 1) {
        if (callback.call(obj[keys[i$1]], keys[i$1], obj[keys[i$1]]) === false) {
          return obj;
        }
      }
    }

    return obj;
  }

  /**
   * 閬嶅巻鏁扮粍鎴栧璞★紝閫氳繃鍑芥暟杩斿洖涓€涓柊鐨勬暟缁勬垨瀵硅薄锛宯ull 鍜 undefined 灏嗚杩囨护鎺夈€俓n   * @param elems
   * @param callback
   * @returns {Array}
   */
  function map(elems, callback) {
    var ref;

    var value;
    var ret = [];

    each(elems, function (i, elem) {
      value = callback(elem, i);

      if (value !== null && value !== undefined) {
        ret.push(value);
      }
    });

    return (ref = []).concat.apply(ref, ret);
  }

  /**
   * 鎶婂璞″悎骞跺埌绗竴涓弬鏁颁腑锛屽苟杩斿洖绗竴涓弬鏁癨n   * @param first
   * @param second
   * @returns {*}
   */
  function merge(first, second) {
    each(second, function (i, val) {
      first.push(val);
    });

    return first;
  }

  /**
   * 鍒犻櫎鏁扮粍涓噸澶嶅厓绱燶n   * @param arr {Array}
   * @returns {Array}
   */
  function unique(arr) {
    var result = [];

    for (var i = 0; i < arr.length; i += 1) {
      if (result.indexOf(arr[i]) === -1) {
        result.push(arr[i]);
      }
    }

    return result;
  }

  var elementDisplay = {};

  /**
   * 鑾峰彇鍏冪礌鐨勯粯璁 display 鏍峰紡鍊硷紝鐢ㄤ簬 .show() 鏂规硶
   * @param nodeName
   * @returns {*}
   */
  function defaultDisplay(nodeName) {
    var element;
    var display;

    if (!elementDisplay[nodeName]) {
      element = document.createElement(nodeName);
      document.body.appendChild(element);
      display = getComputedStyle(element, '').getPropertyValue('display');
      element.parentNode.removeChild(element);
      if (display === 'none') {
        display = 'block';
      }

      elementDisplay[nodeName] = display;
    }

    return elementDisplay[nodeName];
  }

  $.extend({
    each: each,
    merge: merge,
    unique: unique,
    map: map,

    /**
     * 涓€涓 DOM 鑺傜偣鏄惁鍖呭惈鍙︿竴涓 DOM 鑺傜偣
     * @param parent {Node} 鐖惰妭鐐筡n     * @param node {Node} 瀛愯妭鐐筡n     * @returns {Boolean}
     */
    contains: function contains(parent, node) {
      if (parent && !node) {
        return document.documentElement.contains(parent);
      }

      return parent !== node && parent.contains(node);
    },

    /**
     * 灏嗘暟缁勬垨瀵硅薄搴忓垪鍖朶n     * @param obj
     * @returns {String}
     */
    param: function param(obj) {
      if (!isObjectLike(obj)) {
        return '';
      }

      var args = [];

      function destructure(key, value) {
        var keyTmp;

        if (isObjectLike(value)) {
          each(value, function (i, v) {
            if (Array.isArray(value) && !isObjectLike(v)) {
              keyTmp = '';
            } else {
              keyTmp = i;
            }

            destructure((key + "[" + keyTmp + "]"), v);
          });
        } else {
          if (value !== null && value !== '') {
            keyTmp = "=" + (encodeURIComponent(value));
          } else {
            keyTmp = '';
          }

          args.push(encodeURIComponent(key) + keyTmp);
        }
      }

      each(obj, function (key, value) {
        destructure(key, value);
      });

      return args.join('&');
    },
  });

  $.fn.extend({
    /**
     * 閬嶅巻瀵硅薄
     * @param callback {Function}
     * @return {JQ}
     */
    each: function each$1(callback) {
      return each(this, callback);
    },

    /**
     * 閫氳繃閬嶅巻闆嗗悎涓殑鑺傜偣瀵硅薄锛岄€氳繃鍑芥暟杩斿洖涓€涓柊鐨勫璞★紝null 鎴 undefined 灏嗚杩囨护鎺夈€俓n     * @param callback {Function}
     * @returns {JQ}
     */
    map: function map$1(callback) {
      return new JQ(map(this, function (el, i) { return callback.call(el, i, el); }));
    },

    /**
     * 鑾峰彇鎸囧畾 DOM 鍏冪礌锛屾病鏈 index 鍙傛暟鏃讹紝鑾峰彇鎵€鏈 DOM 鐨勬暟缁刓n     * @param index {Number=}
     * @returns {Node|Array}
     */
    get: function get(index) {
      return index === undefined
        ? [].slice.call(this)
        : this[index >= 0 ? index : index + this.length];
    },

    /**
     * array涓彁鍙栫殑鏂规硶銆備粠start寮€濮嬶紝濡傛灉end 鎸囧嚭銆傛彁鍙栦笉鍖呭惈end浣嶇疆鐨勫厓绱犮€俓n     * @param args {start, end}
     * @returns {JQ}
     */
    slice: function slice() {
      var args = [], len = arguments.length;
      while ( len-- ) args[ len ] = arguments[ len ];

      return new JQ([].slice.apply(this, args));
    },

    /**
     * 绛涢€夊厓绱犻泦鍚圽n     * @param selector {String|JQ|Node|Function}
     * @returns {JQ}
     */
    filter: function filter(selector) {
      if (isFunction(selector)) {
        return this.map(function (index, ele) { return (selector.call(ele, index, ele) ? ele : undefined); });
      }

      var $selector = $(selector);

      return this.map(function (index, ele) { return ($selector.index(ele) > -1 ? ele : undefined); });
    },

    /**
     * 浠庡厓绱犻泦鍚堜腑鍒犻櫎鎸囧畾鐨勫厓绱燶n     * @param selector {String|Node|JQ|Function}
     * @return {JQ}
     */
    not: function not(selector) {
      var $excludes = this.filter(selector);

      return this.map(function (index, ele) { return ($excludes.index(ele) > -1 ? undefined : ele); });
    },

    /**
     * 鑾峰彇鍏冪礌鐩稿浜 document 鐨勫亸绉籠n     * @returns {Object}
     */
    offset: function offset() {
      if (this[0]) {
        var offset = this[0].getBoundingClientRect();

        return {
          left: offset.left + window.pageXOffset,
          top: offset.top + window.pageYOffset,
          width: offset.width,
          height: offset.height,
        };
      }

      return null;
    },

    /**
     * 杩斿洖鏈€杩戠殑鐢ㄤ簬瀹氫綅鐨勭埗鍏冪礌
     * @returns {*|JQ}
     */
    offsetParent: function offsetParent() {
      return this.map(function () {
        var parent = this.offsetParent;

        while (parent && $(parent).css('position') === 'static') {
          parent = parent.offsetParent;
        }

        return parent || document.documentElement;
      });
    },

    /**
     * 鑾峰彇鍏冪礌鐩稿浜庣埗鍏冪礌鐨勫亸绉籠n     * @return {Object}
     */
    position: function position() {
      var self = this;

      if (!self[0]) {
        return null;
      }

      var offsetParent;
      var offset;
      var parentOffset = {
        top: 0,
        left: 0,
      };

      if (self.css('position') === 'fixed') {
        offset = self[0].getBoundingClientRect();
      } else {
        offsetParent = self.offsetParent();
        offset = self.offset();
        if (!isNodeName(offsetParent[0], 'html')) {
          parentOffset = offsetParent.offset();
        }

        parentOffset = {
          top: parentOffset.top + offsetParent.css('borderTopWidth'),
          left: parentOffset.left + offsetParent.css('borderLeftWidth'),
        };
      }

      return {
        top: offset.top - parentOffset.top - self.css('marginTop'),
        left: offset.left - parentOffset.left - self.css('marginLeft'),
        width: offset.width,
        height: offset.height,
      };
    },

    /**
     * 鏄剧ず鎸囧畾鍏冪礌
     * @returns {JQ}
     */
    show: function show() {
      return this.each(function () {
        if (this.style.display === 'none') {
          this.style.display = '';
        }

        if (window.getComputedStyle(this, '').getPropertyValue('display') === 'none') {
          this.style.display = defaultDisplay(this.nodeName);
        }
      });
    },

    /**
     * 闅愯棌鎸囧畾鍏冪礌
     * @returns {JQ}
     */
    hide: function hide() {
      return this.each(function () {
        this.style.display = 'none';
      });
    },

    /**
     * 鍒囨崲鍏冪礌鐨勬樉绀虹姸鎬乗n     * @returns {JQ}
     */
    toggle: function toggle() {
      return this.each(function () {
        this.style.display = this.style.display === 'none' ? '' : 'none';
      });
    },

    /**
     * 鏄惁鍚湁鎸囧畾鐨 CSS 绫籠n     * @param className {String}
     * @returns {boolean}
     */
    hasClass: function hasClass(className) {
      if (!this[0] || !className) {
        return false;
      }

      return this[0].classList.contains(className);
    },

    /**
     * 绉婚櫎鎸囧畾灞炴€n     * @param attr {String}
     * @returns {JQ}
     */
    removeAttr: function removeAttr(attr) {
      return this.each(function () {
        this.removeAttribute(attr);
      });
    },

    /**
     * 鍒犻櫎灞炴€у€糪n     * @param name {String}
     * @returns {JQ}
     */
    removeProp: function removeProp(name) {
      return this.each(function () {
        try {
          delete this[name];
        } catch (e) {
          // empty
        }
      });
    },

    /**
     * 鑾峰彇褰撳墠瀵硅薄涓n涓厓绱燶n     * @param index {Number}
     * @returns {JQ}
     */
    eq: function eq(index) {
      var ret = index === -1
        ? this.slice(index)
        : this.slice(index, +index + 1);

      return new JQ(ret);
    },

    /**
     * 鑾峰彇瀵硅薄涓涓€涓厓绱燶n     * @returns {JQ}
     */
    first: function first() {
      return this.eq(0);
    },

    /**
     * 鑾峰彇瀵硅薄涓渶鍚庝竴涓厓绱燶n     * @returns {JQ}
     */
    last: function last() {
      return this.eq(-1);
    },

    /**
     * 鑾峰彇涓€涓厓绱犵殑浣嶇疆銆俓n     * 褰 elem 鍙傛暟娌℃湁缁欏嚭鏃讹紝杩斿洖褰撳墠鍏冪礌鍦ㄥ厔寮熻妭鐐逛腑鐨勪綅缃€俓n     * 鏈夌粰鍑轰簡 elem 鍙傛暟鏃讹紝杩斿洖 elem 鍏冪礌鍦ㄥ綋鍓嶅璞′腑鐨勪綅缃甛n     * @param elem {Selector|Node=}
     * @returns {Number}
     */
    index: function index(elem) {
      if (!elem) {
        // 鑾峰彇褰撳墠 JQ 瀵硅薄鐨勭涓€涓厓绱犲湪鍚岃緢鍏冪礌涓殑浣嶇疆
        return this
          .eq(0)
          .parent()
          .children()
          .get()
          .indexOf(this[0]);
      }

      if (isString(elem)) {
        // 杩斿洖褰撳墠 JQ 瀵硅薄鐨勭涓€涓厓绱犲湪鎸囧畾閫夋嫨鍣ㄥ搴旂殑鍏冪礌涓殑浣嶇疆
        return $(elem)
          .eq(0)
          .parent()
          .children()
          .get()
          .indexOf(this[0]);
      }

      // 杩斿洖鎸囧畾鍏冪礌鍦ㄥ綋鍓 JQ 瀵硅薄涓殑浣嶇疆
      return this
        .get()
        .indexOf(elem);
    },

    /**
     * 鏍规嵁閫夋嫨鍣ㄣ€丏OM鍏冪礌鎴 JQ 瀵硅薄鏉ユ娴嬪尮閰嶅厓绱犻泦鍚堬紝
     * 濡傛灉鍏朵腑鑷冲皯鏈変竴涓厓绱犵鍚堣繖涓粰瀹氱殑琛ㄨ揪寮忓氨杩斿洖true
     * @param selector {String|Node|NodeList|Array|JQ|Window}
     * @returns boolean
     */
    is: function is(selector) {
      var self = this[0];

      if (!self || selector === undefined || selector === null) {
        return false;
      }

      if (isString(selector)) {
        if (self === document || self === window) {
          return false;
        }

        var matchesSelector = self.matches
          || self.matchesSelector
          || self.webkitMatchesSelector
          || self.mozMatchesSelector
          || self.oMatchesSelector
          || self.msMatchesSelector;

        return matchesSelector.call(self, selector);
      }

      if (selector === document || selector === window) {
        return self === selector;
      }

      if (selector.nodeType || isArrayLike(selector)) {
        var $compareWith = selector.nodeType ? [selector] : selector;

        for (var i = 0; i < $compareWith.length; i += 1) {
          if ($compareWith[i] === self) {
            return true;
          }
        }

        return false;
      }

      return false;
    },

    /**
     * 鏍规嵁 CSS 閫夋嫨鍣ㄦ壘鍒板悗浠ｈ妭鐐圭殑闆嗗悎
     * @param selector {String}
     * @returns {JQ}
     */
    find: function find(selector) {
      var foundElements = [];

      this.each(function (i, _this) {
        var nodeType = _this.nodeType;

        if (nodeType !== 1 && nodeType !== 9) {
          // 涓嶆槸 element 鍜 document 鍒欒烦杩嘰n          return;
        }

        merge(foundElements, _this.querySelectorAll(selector));
      });

      return new JQ(foundElements);
    },

    /**
     * 鎵惧埌鐩存帴瀛愬厓绱犵殑鍏冪礌闆嗗悎
     * @param selector {String=}
     * @returns {JQ}
     */
    children: function children(selector) {
      var children = [];

      this.each(function (_, _this) {
        each(_this.childNodes, function (__, childNode) {
          if (childNode.nodeType !== 1) {
            return;
          }

          if (!selector || (selector && $(childNode).is(selector))) {
            children.push(childNode);
          }
        });
      });

      return new JQ(unique(children));
    },

    /**
     * 淇濈暀鍚湁鎸囧畾瀛愬厓绱犵殑鍏冪礌锛屽幓鎺変笉鍚湁鎸囧畾瀛愬厓绱犵殑鍏冪礌
     * @param selector {String|Node|JQ|NodeList|Array}
     * @return {JQ}
     */
    has: function has(selector) {
      var $targets = isString(selector) ? this.find(selector) : $(selector);
      var length = $targets.length;

      return this.filter(function () {
        for (var i = 0; i < length; i += 1) {
          if ($.contains(this, $targets[i])) {
            return true;
          }
        }

        return false;
      });
    },

    /**
     * 鍙栧緱鍚岃緢鍏冪礌鐨勯泦鍚圽n     * @param selector {String=}
     * @returns {JQ}
     */
    siblings: function siblings(selector) {
      return this.prevAll(selector).add(this.nextAll(selector));
    },

    /**
     * 杩斿洖棣栧厛鍖归厤鍒扮殑鐖惰妭鐐癸紝鍖呭惈鐖惰妭鐐筡n     * @param selector {String}
     * @returns {JQ}
     */
    closest: function closest(selector) {
      var self = this;

      if (!self.is(selector)) {
        self = self.parents(selector).eq(0);
      }

      return self;
    },

    /**
     * 鍒犻櫎鎵€鏈夊尮閰嶇殑鍏冪礌
     * @returns {JQ}
     */
    remove: function remove() {
      return this.each(function (i, _this) {
        if (_this.parentNode) {
          _this.parentNode.removeChild(_this);
        }
      });
    },

    /**
     * 娣诲姞鍖归厤鐨勫厓绱犲埌褰撳墠瀵硅薄涓璡n     * @param selector {String|JQ}
     * @returns {JQ}
     */
    add: function add(selector) {
      return new JQ(unique(merge(this.get(), $(selector))));
    },

    /**
     * 鍒犻櫎瀛愯妭鐐筡n     * @returns {JQ}
     */
    empty: function empty() {
      return this.each(function () {
        this.innerHTML = '';
      });
    },

    /**
     * 閫氳繃娣卞害鍏嬮殕鏉ュ鍒堕泦鍚堜腑鐨勬墍鏈夊厓绱犮€俓n     * (閫氳繃鍘熺敓 cloneNode 鏂规硶娣卞害鍏嬮殕鏉ュ鍒堕泦鍚堜腑鐨勬墍鏈夊厓绱犮€傛鏂规硶涓嶄細鏈夋暟鎹拰浜嬩欢澶勭悊绋嬪簭澶嶅埗鍒版柊鐨勫厓绱犮€傝繖鐐瑰拰jquery涓埄鐢ㄤ竴涓弬鏁版潵纭畾鏄惁澶嶅埗鏁版嵁鍜屼簨浠跺鐞嗕笉鐩稿悓銆?
     * @returns {JQ}
     */
    clone: function clone() {
      return this.map(function () {
        return this.cloneNode(true);
      });
    },

    /**
     * 鐢ㄦ柊鍏冪礌鏇挎崲褰撳墠鍏冪礌
     * @param newContent {String|Node|NodeList|JQ}
     * @returns {JQ}
     */
    replaceWith: function replaceWith(newContent) {
      return this.before(newContent).remove();
    },

    /**
     * 灏嗚〃鍗曞厓绱犵殑鍊肩粍鍚堟垚閿€煎鏁扮粍
     * @returns {Array}
     */
    serializeArray: function serializeArray() {
      var result = [];
      var elem = this[0];

      if (!elem || !elem.elements) {
        return result;
      }

      $([].slice.call(elem.elements)).each(function () {
        var $elem = $(this);
        var type = $elem.attr('type');
        if (
          this.nodeName.toLowerCase() !== 'fieldset'
          && !this.disabled
          && ['submit', 'reset', 'button'].indexOf(type) === -1
          && (['radio', 'checkbox'].indexOf(type) === -1 || this.checked)
        ) {
          result.push({
            name: $elem.attr('name'),
            value: $elem.val(),
          });
        }
      });

      return result;
    },

    /**
     * 灏嗚〃鍗曞厓绱犳垨瀵硅薄搴忓垪鍖朶n     * @returns {String}
     */
    serialize: function serialize() {
      var result = [];

      each(this.serializeArray(), function (i, elem) {
        result.push(((encodeURIComponent(elem.name)) + "=" + (encodeURIComponent(elem.value))));
      });

      return result.join('&');
    },
  });

  /**
   * val - 鑾峰彇鎴栬缃厓绱犵殑鍊糪n   * @param value {String=}
   * @return {*|JQ}
   */
  /**
   * html - 鑾峰彇鎴栬缃厓绱犵殑 HTML
   * @param value {String=}
   * @return {*|JQ}
   */
  /**
   * text - 鑾峰彇鎴栬缃厓绱犵殑鍐呭
   * @param value {String=}
   * @return {*|JQ}
   */
  each(['val', 'html', 'text'], function (nameIndex, name) {
    var props = {
      0: 'value',
      1: 'innerHTML',
      2: 'textContent',
    };

    var defaults = {
      0: undefined,
      1: undefined,
      2: null,
    };

    $.fn[name] = function (value) {
      if (value === undefined) {
        // 鑾峰彇鍊糪n        return this[0] ? this[0][props[nameIndex]] : defaults[nameIndex];
      }

      // 璁剧疆鍊糪n      return this.each(function (i, elem) {
        elem[props[nameIndex]] = value;
      });
    };
  });

  /**
   * attr - 鑾峰彇鎴栬缃厓绱犵殑灞炴€у€糪n   * @param {name|props|key,value=}
   * @return {String|JQ}
   */
  /**
   * prop - 鑾峰彇鎴栬缃厓绱犵殑灞炴€у€糪n   * @param {name|props|key,value=}
   * @return {String|JQ}
   */
  /**
   * css - 鑾峰彇鎴栬缃厓绱犵殑鏍峰紡
   * @param {name|props|key,value=}
   * @return {String|JQ}
   */
  each(['attr', 'prop', 'css'], function (nameIndex, name) {
    function set(elem, key, value) {
      if (nameIndex === 0) {
        elem.setAttribute(key, value);
      } else if (nameIndex === 1) {
        elem[key] = value;
      } else {
        elem.style[key] = value;
      }
    }

    function get(elem, key) {
      if (!elem) {
        return undefined;
      }

      if (nameIndex === 0) {
        return elem.getAttribute(key);
      }

      if (nameIndex === 1) {
        return elem[key];
      }

      return window.getComputedStyle(elem, null).getPropertyValue(key);
    }

    $.fn[name] = function (key, value) {
      var argLength = arguments.length;

      if (argLength === 1 && isString(key)) {
        // 鑾峰彇鍊糪n        return get(this[0], key);
      }

      // 璁剧疆鍊糪n      return this.each(function (i, elem) {
        if (argLength === 2) {
          set(elem, key, value);
        } else {
          each(key, function (k, v) {
            set(elem, k, v);
          });
        }
      });
    };
  });

  /**
   * addClass - 娣诲姞 CSS 绫伙紝澶氫釜绫诲悕鐢ㄧ┖鏍煎垎鍓瞈n   * @param className {String}
   * @return {JQ}
   */
  /**
   * removeClass - 绉婚櫎 CSS 绫伙紝澶氫釜绫诲悕鐢ㄧ┖鏍煎垎鍓瞈n   * @param className {String}
   * @return {JQ}
   */
  /**
   * toggleClass - 鍒囨崲 CSS 绫诲悕锛屽涓被鍚嶇敤绌烘牸鍒嗗壊
   * @param className {String}
   * @return {JQ}
   */
  each(['add', 'remove', 'toggle'], function (nameIndex, name) {
    $.fn[(name + "Class")] = function (className) {
      if (!className) {
        return this;
      }

      var classes = className.split(' ');

      return this.each(function (i, elem) {
        each(classes, function (j, cls) {
          elem.classList[name](cls);
        });
      });
    };
  });

  /**
   * width - 鑾峰彇鍏冪礌鐨勫搴n   * @return {Number}
   */
  /**
   * height - 鑾峰彇鍏冪礌鐨勯珮搴n   * @return {Number}
   */
  each({
    Width: 'width',
    Height: 'height',
  }, function (prop, name) {
    $.fn[name] = function (val) {
      if (val === undefined) {
        // 鑾峰彇
        var elem = this[0];

        if (isWindow(elem)) {
          return elem[("inner" + prop)];
        }

        if (isDocument(elem)) {
          return elem.documentElement[("scroll" + prop)];
        }

        var $elem = $(elem);

        // IE10銆両E11 鍦 box-sizing:border-box 鏃讹紝涓嶄細鍖呭惈 padding 鍜 border锛岃繖閲岃繘琛屼慨澶峔n        var IEFixValue = 0;
        var isWidth = name === 'width';
        if ('ActiveXObject' in window) { // 鍒ゆ柇鏄 IE 娴忚鍣╘n          if ($elem.css('box-sizing') === 'border-box') {
            IEFixValue = parseFloat($elem.css(("padding-" + (isWidth ? 'left' : 'top'))))
              + parseFloat($elem.css(("padding-" + ((isWidth ? 'right' : 'bottom')))))
              + parseFloat($elem.css(("border-" + (isWidth ? 'left' : 'top') + "-width")))
              + parseFloat($elem.css(("border-" + (isWidth ? 'right' : 'bottom') + "-width")));
          }
        }

        return parseFloat($(elem).css(name)) + IEFixValue;
      }

      // 璁剧疆
      /* eslint no-restricted-globals: 0 */
      if (!isNaN(Number(val)) && val !== '') {
        val += 'px';
      }

      return this.css(name, val);
    };
  });

  /**
   * innerWidth - 鑾峰彇鍏冪礌鐨勫搴︼紝鍖呭惈鍐呰竟璺漒n   * @return {Number}
   */
  /**
   * innerHeight - 鑾峰彇鍏冪礌鐨勯珮搴︼紝鍖呭惈鍐呰竟璺漒n   * @return {Number}
   */
  each({
    Width: 'width',
    Height: 'height',
  }, function (prop, name) {
    $.fn[("inner" + prop)] = function () {
      var value = this[name]();
      var $elem = $(this[0]);

      if ($elem.css('box-sizing') !== 'border-box') {
        value += parseFloat($elem.css(("padding-" + (name === 'width' ? 'left' : 'top'))));
        value += parseFloat($elem.css(("padding-" + (name === 'width' ? 'right' : 'bottom'))));
      }

      return value;
    };
  });

  function dir(nodes, selector, nameIndex, node) {
    var ret = [];
    var elem;

    nodes.each(function (j, _this) {
      elem = _this[node];
      while (elem) {
        if (nameIndex === 2) {
          // prevUntil
          if (!selector || (selector && $(elem).is(selector))) {
            break;
          }

          ret.push(elem);
        } else if (nameIndex === 0) {
          // prev
          if (!selector || (selector && $(elem).is(selector))) {
            ret.push(elem);
          }

          break;
        } else if (!selector || (selector && $(elem).is(selector))) {
          // prevAll
          ret.push(elem);
        }

        elem = elem[node];
      }
    });

    return new JQ(unique(ret));
  }

  /**
   * prev - 鍙栧緱鍓嶄竴涓尮閰嶇殑鍏冪礌
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * prevAll - 鍙栧緱鍓嶉潰鎵€鏈夊尮閰嶇殑鍏冪礌
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * prevUntil - 鍙栧緱鍓嶉潰鐨勬墍鏈夊厓绱狅紝鐩村埌閬囧埌鍖归厤鐨勫厓绱狅紝涓嶅寘鍚尮閰嶇殑鍏冪礌
   * @param selector {String=}
   * @return {JQ}
   */
  each(['', 'All', 'Until'], function (nameIndex, name) {
    $.fn[("prev" + name)] = function (selector) {
      // prevAll銆乸revUntil 闇€瑕佹妸鍏冪礌鐨勯『搴忓€掑簭澶勭悊锛屼互渚垮拰 jQuery 鐨勭粨鏋滀竴鑷碶n      var $nodes = nameIndex === 0 ? this : $(this.get().reverse());

      return dir($nodes, selector, nameIndex, 'previousElementSibling');
    };
  });

  /**
   * next - 鍙栧緱鍚庝竴涓尮閰嶇殑鍏冪礌
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * nextAll - 鍙栧緱鍚庨潰鎵€鏈夊尮閰嶇殑鍏冪礌
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * nextUntil - 鍙栧緱鍚庨潰鎵€鏈夊尮閰嶇殑鍏冪礌锛岀洿鍒伴亣鍒板尮閰嶇殑鍏冪礌锛屼笉鍖呭惈鍖归厤鐨勫厓绱燶n   * @param selector {String=}
   * @return {JQ}
   */
  each(['', 'All', 'Until'], function (nameIndex, name) {
    $.fn[("next" + name)] = function (selector) {
      return dir(this, selector, nameIndex, 'nextElementSibling');
    };
  });

  /**
   * parent - 鍙栧緱鍖归厤鐨勭洿鎺ョ埗鍏冪礌
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * parents - 鍙栧緱鎵€鏈夊尮閰嶇殑鐖跺厓绱燶n   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * parentUntil - 鍙栧緱鎵€鏈夌殑鐖跺厓绱狅紝鐩村埌閬囧埌鍖归厤鐨勫厓绱狅紝涓嶅寘鍚尮閰嶇殑鍏冪礌
   * @param selector {String=}
   * @return {JQ}
   */
  each(['', 's', 'sUntil'], function (nameIndex, name) {
    $.fn[("parent" + name)] = function (selector) {
      // parents銆乸arentsUntil 闇€瑕佹妸鍏冪礌鐨勯『搴忓弽鍚戝鐞嗭紝浠ヤ究鍜 jQuery 鐨勭粨鏋滀竴鑷碶n      var $nodes = nameIndex === 0 ? this : $(this.get().reverse());

      return dir($nodes, selector, nameIndex, 'parentNode');
    };
  });

  /**
   * append - 鍦ㄥ厓绱犲唴閮ㄨ拷鍔犲唴瀹筡n   * @param newChild {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * prepend - 鍦ㄥ厓绱犲唴閮ㄥ墠缃唴瀹筡n   * @param newChild {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  each(['append', 'prepend'], function (nameIndex, name) {
    $.fn[name] = function (newChild) {
      var newChilds;
      var copyByClone = this.length > 1;

      if (isString(newChild) && (newChild[0] !== '<' || newChild[newChild.length - 1] !== '>')) {
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = newChild;
        newChilds = [].slice.call(tempDiv.childNodes);
      } else {
        newChilds = $(newChild).get();
      }

      if (nameIndex === 1) {
        // prepend
        newChilds.reverse();
      }

      return this.each(function (i, _this) {
        each(newChilds, function (j, child) {
          // 涓€涓厓绱犺鍚屾椂杩藉姞鍒板涓厓绱犱腑锛岄渶瑕佸厛澶嶅埗涓€浠斤紝鐒跺悗杩藉姞
          if (copyByClone && i > 0) {
            child = child.cloneNode(true);
          }

          if (nameIndex === 0) {
            // append
            _this.appendChild(child);
          } else {
            // prepend
            _this.insertBefore(child, _this.childNodes[0]);
          }
        });
      });
    };
  });

  /**
   * insertBefore - 鎻掑叆鍒版寚瀹氬厓绱犵殑鍓嶉潰
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * insertAfter - 鎻掑叆鍒版寚瀹氬厓绱犵殑鍚庨潰
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  each(['insertBefore', 'insertAfter'], function (nameIndex, name) {
    $.fn[name] = function (selector) {
      var $elem = $(selector);

      return this.each(function (i, _this) {
        $elem.each(function (j, elem) {
          elem.parentNode.insertBefore(
            $elem.length === 1 ? _this : _this.cloneNode(true),
            nameIndex === 0 ? elem : elem.nextSibling
          );
        });
      });
    };
  });

  /**
   * appendTo - 杩藉姞鍒版寚瀹氬厓绱犲唴瀹筡n   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * prependTo - 鍓嶇疆鍒版寚瀹氬厓绱犲唴閮╘n   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * before - 鎻掑叆鍒版寚瀹氬厓绱犲墠闈n   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * after - 鎻掑叆鍒版寚瀹氬厓绱犲悗闈n   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * replaceAll - 鏇挎崲鎺夋寚瀹氬厓绱燶n   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  each({
    appendTo: 'append',
    prependTo: 'prepend',
    before: 'insertBefore',
    after: 'insertAfter',
    replaceAll: 'replaceWith',
  }, function (name, original) {
    $.fn[name] = function (selector) {
      $(selector)[original](this);
      return this;
    };
  });

  var dataNS = 'mduiElementDataStorage';

  $.extend({
    /**
     * 鍦ㄦ寚瀹氬厓绱犱笂瀛樺偍鏁版嵁锛屾垨浠庢寚瀹氬厓绱犱笂璇诲彇鏁版嵁
     * @param elem 蹇呴』锛 DOM 鍏冪礌
     * @param key 蹇呴』锛岄敭鍚峔n     * @param value 鍙€夛紝鍊糪n     */
    data: function data(elem, key, value) {
      var data = {};

      if (value !== undefined) {
        // 鏍规嵁 key銆乿alue 璁剧疆鍊糪n        data[key] = value;
      } else if (isObjectLike(key)) {
        // 鏍规嵁閿€煎璁剧疆鍊糪n        data = key;
      } else if (key === undefined) {
        // 鑾峰彇鎵€鏈夊€糪n        var result = {};

        each(elem.attributes, function (i, attribute) {
          var name = attribute.name;

          if (name.indexOf('data-') === 0) {
            var prop = name.slice(5).replace(/-./g, function (u) { return u.charAt(1).toUpperCase(); });

            result[prop] = attribute.value;
          }
        });

        if (elem[dataNS]) {
          each(elem[dataNS], function (k, v) {
            result[k] = v;
          });
        }

        return result;
      } else if (elem[dataNS] && (key in elem[dataNS])) {
        // 鑾峰彇鎸囧畾鍊糪n        return elem[dataNS][key];
      } else {
        // 浠 data- 涓幏鍙栨寚瀹氬€糪n        var dataKey = elem.getAttribute(("data-" + key));

        if (dataKey) {
          return dataKey;
        }

        return undefined;
      }

      // 璁剧疆鍊糪n      if (!elem[dataNS]) {
        elem[dataNS] = {};
      }

      each(data, function (k, v) {
        elem[dataNS][k] = v;
      });

      return undefined;
    },

    /**
     * 绉婚櫎鎸囧畾鍏冪礌涓婂瓨鏀剧殑鏁版嵁
     * @param elem 蹇呴』锛孌OM 鍏冪礌
     * @param key 蹇呴』锛岄敭鍚峔n     */
    removeData: function removeData(elem, key) {
      if (elem[dataNS] && elem[dataNS][key]) {
        elem[dataNS][key] = null;
        delete elem[dataNS][key];
      }
    },
  });

  $.fn.extend({
    /**
     * 鍦ㄥ厓绱犱笂璇诲彇鎴栬缃暟鎹甛n     * @param key 蹇呴』
     * @param value
     * @returns {*}
     */
    data: function data(key, value) {
      if (value === undefined) {
        if (isObjectLike(key)) {
          // 鍚屾椂璁剧疆澶氫釜鍊糪n          return this.each(function (i, elem) {
            $.data(elem, key);
          });
        }

        if (this[0]) {
          // 鑾峰彇鍊糪n          return $.data(this[0], key);
        }

        return undefined;
      }

      // 璁剧疆鍊糪n      return this.each(function (i, elem) {
        $.data(elem, key, value);
      });
    },

    /**
     * 绉婚櫎鍏冪礌涓婂瓨鍌ㄧ殑鏁版嵁
     * @param key 蹇呴』
     * @returns {*}
     */
    removeData: function removeData(key) {
      return this.each(function (i, elem) {
        $.removeData(elem, key);
      });
    },
  });

  !function(){try{return new e("test")}catch(e){}var e=function(e,t){t=t||{bubbles:!1,cancelable:!1};var n=document.createEvent("MouseEvent");return n.initMouseEvent(e,t.bubbles,t.cancelable,window,0,0,0,0,0,!1,!1,!1,!1,0,null),n};e.prototype=Event.prototype,window.MouseEvent=e;}();

  !function(){function t(t,e){e=e||{bubbles:!1,cancelable:!1,detail:void 0};var n=document.createEvent("CustomEvent");return n.initCustomEvent(t,e.bubbles,e.cancelable,e.detail),n}"function"!=typeof window.CustomEvent&&(t.prototype=window.Event.prototype,window.CustomEvent=t);}();

  // 瀛樺偍浜嬩欢
  var handlers = {
    // i: { // 鍏冪礌ID
    //   j: { // 浜嬩欢ID
    //     e: 浜嬩欢鍚峔n    //     fn: 浜嬩欢澶勭悊鍑芥暟
    //     i: 浜嬩欢ID
    //     proxy:
    //     sel: 閫夋嫨鍣╘n    //   }
    // }
  };

  // 鍏冪礌ID
  var mduiElementId = 1;

  function fnFalse() {
    return false;
  }

  /**
   * 涓哄厓绱犺祴浜堜竴涓敮涓€鐨処D
   * @param element
   * @returns {number|*}
   */
  function getElementId(element) {
    if (!element.mduiElementId) {
      mduiElementId += 1;
      element.mduiElementId = mduiElementId;
    }

    return element.mduiElementId;
  }

  /**
   * 鑾峰彇鍖归厤鐨勪簨浠禱n   * @param element
   * @param eventName
   * @param func
   * @param selector
   * @returns {Array}
   */
  function getHandlers(element, eventName, func, selector) {
    return (handlers[getElementId(element)] || []).filter(function (handler) { return handler
      && (!eventName || handler.e === eventName)
      && (!func || handler.fn.toString() === func.toString())
      && (!selector || handler.sel === selector); });
  }

  /**
   * 娣诲姞浜嬩欢鐩戝惉
   * @param element
   * @param eventName
   * @param func
   * @param data
   * @param selector
   */
  function add(element, eventName, func, data, selector) {
    var elementId = getElementId(element);

    if (!handlers[elementId]) {
      handlers[elementId] = [];
    }

    // 浼犲叆 data.useCapture 鏉ヨ缃 useCapture: true
    var useCapture = false;
    if (isObjectLike(data) && data.useCapture) {
      useCapture = true;
    }

    eventName.split(' ').forEach(function (event) {
      var handler = {
        e: event,
        fn: func,
        sel: selector,
        i: handlers[elementId].length,
      };

      function callFn(e, elem) {
        // 鍥犱负榧犳爣浜嬩欢妯℃嫙浜嬩欢鐨 detail 灞炴€ф槸鍙鐨勶紝鍥犳鍦 e._detail 涓瓨鍌ㄥ弬鏁癨n        /* eslint no-underscore-dangle: 0 */
        var result = func.apply(elem, e._detail === undefined ? [e] : [e].concat(e._detail));

        if (result === false) {
          e.preventDefault();
          e.stopPropagation();
        }
      }

      function proxyfn(e) {
        e._data = data;

        if (selector) {
          // 浜嬩欢浠ｇ悊
          $(element)
            .find(selector)
            .get()
            .reverse()
            .forEach(function (elem) {
              if (elem === e.target || $.contains(elem, e.target)) {
                callFn(e, elem);
              }
            });
        } else {
          // 涓嶄娇鐢ㄤ簨浠朵唬鐞哱n          callFn(e, element);
        }
      }

      handler.proxy = proxyfn;
      handlers[elementId].push(handler);
      element.addEventListener(handler.e, proxyfn, useCapture);
    });
  }

  /**
   * 绉婚櫎浜嬩欢鐩戝惉
   * @param element
   * @param eventName
   * @param func
   * @param selector
   */
  function remove(element, eventName, func, selector) {
    (eventName || '').split(' ').forEach(function (event) {
      getHandlers(element, event, func, selector).forEach(function (handler) {
        delete handlers[getElementId(element)][handler.i];
        element.removeEventListener(handler.e, handler.proxy, false);
      });
    });
  }

  $.fn.extend({
    /**
     * DOM 鍔犺浇瀹屾瘯鍚庤皟鐢ㄧ殑鍑芥暟
     * @param callback
     * @returns {ready}
     */
    ready: function ready(callback) {
      if (/complete|loaded|interactive/.test(document.readyState) && document.body) {
        callback($);
      } else {
        document.addEventListener('DOMContentLoaded', function () {
          callback($);
        }, false);
      }

      return this;
    },

    /**
     * 缁戝畾浜嬩欢
     *
     * $().on({eventName: fn}, selector, data);
     * $().on({eventName: fn}, selector)
     * $().on({eventName: fn})
     * $().on(eventName, selector, data, fn);
     * $().on(eventName, selector, fn);
     * $().on(eventName, data, fn);
     * $().on(eventName, fn);
     * $().on(eventName, false);
     *
     * @param eventName
     * @param selector
     * @param data
     * @param callback
     * @param one 鏄惁鏄 one 鏂规硶锛屽彧鍦 JQ 鍐呴儴浣跨敤
     * @returns
     */
    on: function on(eventName, selector, data, callback, one) {
      var self = this;

      // 榛樿
      // $().on(event, selector, data, callback)

      // event 浣跨敤 浜嬩欢:鍑芥暟 閿€煎
      // event = {
      //   'event1': callback1,
      //   'event2': callback2
      // }
      //
      // $().on(event, selector, data)
      if (eventName && !isString(eventName)) {
        each(eventName, function (type, fn) {
          self.on(type, selector, data, fn);
        });

        return self;
      }

      // selector 涓嶅瓨鍦╘n      // $().on(event, data, callback)
      if (!isString(selector) && !isFunction(callback) && callback !== false) {
        callback = data;
        data = selector;
        selector = undefined;
      }

      // data 涓嶅瓨鍦╘n      // $().on(event, callback)
      if (isFunction(data) || data === false) {
        callback = data;
        data = undefined;
      }

      // callback 涓 false
      // $().on(event, false)
      if (callback === false) {
        callback = fnFalse;
      }

      if (one === 1) {
        var origCallback = callback;
        callback = function () {
          self.off(eventName, selector, callback);
          /* eslint prefer-rest-params: 0 */
          return origCallback.apply(this, arguments);
        };
      }

      return this.each(function () {
        add(this, eventName, callback, data, selector);
      });
    },

    /**
     * 缁戝畾浜嬩欢锛屽彧瑙﹀彂涓€娆n     * @param eventName
     * @param selector
     * @param data
     * @param callback
     */
    one: function one(eventName, selector, data, callback) {
      var self = this;

      if (!isString(eventName)) {
        each(eventName, function (type, fn) {
          type.split(' ').forEach(function (eName) {
            self.on(eName, selector, data, fn, 1);
          });
        });
      } else {
        eventName.split(' ').forEach(function (eName) {
          self.on(eName, selector, data, callback, 1);
        });
      }

      return this;
    },

    /**
     * 鍙栨秷缁戝畾浜嬩欢
     *
     * $().off(eventName, selector);
     * $().off(eventName, callback);
     * $().off(eventName, false);
     *
     */
    off: function off(eventName, selector, callback) {
      var self = this;

      // event 浣跨敤 浜嬩欢:鍑芥暟 閿€煎
      // event = {
      //   'event1': callback1,
      //   'event2': callback2
      // }
      //
      // $().off(event, selector)
      if (eventName && !isString(eventName)) {
        each(eventName, function (type, fn) {
          self.off(type, selector, fn);
        });

        return self;
      }

      // selector 涓嶅瓨鍦╘n      // $().off(event, callback)
      if (!isString(selector) && !isFunction(callback) && callback !== false) {
        callback = selector;
        selector = undefined;
      }

      // callback 涓 false
      // $().off(event, false)
      if (callback === false) {
        callback = fnFalse;
      }

      return self.each(function () {
        remove(this, eventName, callback, selector);
      });
    },

    /**
     * 瑙﹀彂涓€涓簨浠禱n     * @param eventName
     * @param data
     * @returns {*|JQ}
     */
    trigger: function trigger(eventName, data) {
      var isMouseEvent = ['click', 'mousedown', 'mouseup', 'mousemove'].indexOf(eventName) > -1;
      var evt;

      if (isMouseEvent) {
        // Note: MouseEvent 鏃犳硶浼犲叆 detail 鍙傛暟
        evt = new MouseEvent(eventName, {
          bubbles: true,
          cancelable: true,
        });
      } else {
        evt = new CustomEvent(eventName, {
          detail: data,
          bubbles: true,
          cancelable: true,
        });
      }

      evt._detail = data;

      return this.each(function () {
        this.dispatchEvent(evt);
      });
    },
  });

  var globalOptions = {};
  var jsonpID = 0;

  // 鍏ㄥ眬浜嬩欢鍚峔n  var ajaxEvent = {
    ajaxStart: 'start.mdui.ajax',
    ajaxSuccess: 'success.mdui.ajax',
    ajaxError: 'error.mdui.ajax',
    ajaxComplete: 'complete.mdui.ajax',
  };

  /**
   * 鍒ゆ柇姝よ姹傛柟娉曟槸鍚﹂€氳繃鏌ヨ瀛楃涓叉彁浜ゅ弬鏁癨n   * @param method 璇锋眰鏂规硶锛屽ぇ鍐橽n   * @returns {boolean}
   */
  function isQueryStringData(method) {
    return ['GET', 'HEAD'].indexOf(method) >= 0;
  }

  /**
   * 娣诲姞鍙傛暟鍒 URL 涓婏紝涓 URL 涓笉瀛樺湪 ? 鏃讹紝鑷姩鎶婄涓€涓 & 鏇挎崲涓 ?
   * @param url
   * @param query 鍙傛暟 key=value
   * @returns {string}
   */
  function appendQuery(url, query) {
    return ((url + "&" + query)).replace(/[&?]{1,2}/, '?');
  }

  $.extend({

    /**
     * 涓 ajax 璇锋眰璁剧疆鍏ㄥ眬閰嶇疆鍙傛暟
     * @param options
     */
    ajaxSetup: function ajaxSetup(options) {
      $.extend(globalOptions, options || {});
    },

    /**
     * 鍙戦€ ajax 璇锋眰
     * @param options
     */
    ajax: function ajax(options) {
      // 閰嶇疆鍙傛暟
      var defaults = {
        // 璇锋眰鏂瑰紡
        method: 'GET',
        // 璇锋眰鐨勬暟鎹紝鏌ヨ瀛楃涓叉垨瀵硅薄
        data: false,
        // 鏄惁鎶婃暟鎹浆鎹负鏌ヨ瀛楃涓插彂閫侊紝涓 false 鏃朵笉杩涜鑷姩杞崲銆俓n        processData: true,
        // 鏄惁涓哄紓姝ヨ姹俓n        async: true,
        // 鏄惁浠庣紦瀛樹腑璇诲彇锛屽彧瀵 GET/HEAD 璇锋眰鏈夋晥锛宒ataType 涓 jsonp 鏃朵负 false
        cache: true,
        // HTTP 璁块棶璁よ瘉鐨勭敤鎴峰悕
        username: '',
        // HTTP 璁块棶璁よ瘉鐨勫瘑鐮乗n        password: '',
        // 涓€涓敭鍊煎锛岄殢鐫€璇锋眰涓€璧峰彂閫乗n        headers: {},
        // 璁剧疆 XHR 瀵硅薄
        xhrFields: {},
        // 涓€涓 HTTP 浠ｇ爜鍜屽嚱鏁扮殑瀵硅薄
        statusCode: {},
        // 棰勬湡鏈嶅姟鍣ㄨ繑鍥炵殑鏁版嵁绫诲瀷 text銆乯son銆乯sonp
        dataType: 'text',
        // jsonp 璇锋眰鐨勫洖璋冨嚱鏁板悕绉癨n        jsonp: 'callback',
        // 锛坰tring 鎴 Function锛変娇鐢ㄦ寚瀹氱殑鍥炶皟鍑芥暟鍚嶄唬鏇胯嚜鍔ㄧ敓鎴愮殑鍥炶皟鍑芥暟鍚峔n        jsonpCallback: function () {
          jsonpID += 1;

          return ("mduijsonp_" + (Date.now()) + "_" + jsonpID);
        },
        // 鍙戦€佷俊鎭嚦鏈嶅姟鍣ㄦ椂鍐呭缂栫爜绫诲瀷
        contentType: 'application/x-www-form-urlencoded',
        // 璁剧疆璇锋眰瓒呮椂鏃堕棿锛堟绉掞級
        timeout: 0,
        // 鏄惁鍦 document 涓婅Е鍙戝叏灞€ ajax 浜嬩欢
        global: true,
        // beforeSend:    function (XMLHttpRequest) 璇锋眰鍙戦€佸墠鎵ц锛岃繑鍥 false 鍙彇娑堟湰娆 ajax 璇锋眰
        // success:       function (data, textStatus, XMLHttpRequest) 璇锋眰鎴愬姛鏃惰皟鐢╘n        // error:         function (XMLHttpRequest, textStatus) 璇锋眰澶辫触鏃惰皟鐢╘n        // statusCode:    {404: function ()}
        //                200-299涔嬮棿鐨勭姸鎬佺爜琛ㄧず鎴愬姛锛屽弬鏁板拰 success 鍥炶皟涓€鏍凤紱鍏朵粬鐘舵€佺爜琛ㄧず澶辫触锛屽弬鏁板拰 error 鍥炶皟涓€鏍穃n        // complete:      function (XMLHttpRequest, textStatus) 璇锋眰瀹屾垚鍚庡洖璋冨嚱鏁 (璇锋眰鎴愬姛鎴栧け璐ヤ箣鍚庡潎璋冪敤)
      };

      // 鍥炶皟鍑芥暟
      var callbacks = [
        'beforeSend',
        'success',
        'error',
        'statusCode',
        'complete' ];

      // 鏄惁宸插彇娑堣姹俓n      var isCanceled = false;

      // 淇濆瓨鍏ㄥ眬閰嶇疆
      var globals = globalOptions;

      // 浜嬩欢鍙傛暟
      var eventParams = {};

      // 鍚堝苟鍏ㄥ眬鍙傛暟鍒伴粯璁ゅ弬鏁帮紝鍏ㄥ眬鍥炶皟鍑芥暟涓嶈鐩朶n      each(globals, function (key, value) {
        if (callbacks.indexOf(key) < 0) {
          defaults[key] = value;
        }
      });

      // 鍙傛暟鍚堝苟
      options = $.extend({}, defaults, options);

      /**
       * 瑙﹀彂鍏ㄥ眬浜嬩欢
       * @param event string 浜嬩欢鍚峔n       * @param xhr XMLHttpRequest 浜嬩欢鍙傛暟
       */
      function triggerEvent(event, xhr) {
        if (options.global) {
          $(document).trigger(event, xhr);
        }
      }

      /**
       * 瑙﹀彂 XHR 鍥炶皟鍜屼簨浠禱n       * @param callback string 鍥炶皟鍑芥暟鍚嶇О
       * @param args
       */
      function triggerCallback(callback) {
        var args = [], len = arguments.length - 1;
        while ( len-- > 0 ) args[ len ] = arguments[ len + 1 ];

        var result1;
        var result2;

        if (callback) {
          // 鍏ㄥ眬鍥炶皟
          if (callback in globals) {
            result1 = globals[callback].apply(globals, args);
          }

          // 鑷畾涔夊洖璋僜n          if (options[callback]) {
            result2 = options[callback].apply(options, args);
          }

          // beforeSend 鍥炶皟杩斿洖 false 鏃跺彇娑 ajax 璇锋眰
          if (callback === 'beforeSend' && (result1 === false || result2 === false)) {
            isCanceled = true;
          }
        }
      }

      // 璇锋眰鏂瑰紡杞负澶у啓
      var method = options.method.toUpperCase();

      // 榛樿浣跨敤褰撳墠椤甸潰 URL
      if (!options.url) {
        options.url = window.location.toString();
      }

      // 闇€瑕佸彂閫佺殑鏁版嵁
      // GET/HEAD 璇锋眰鍜 processData 涓 true 鏃讹紝杞崲涓烘煡璇㈠瓧绗︿覆鏍煎紡锛岀壒娈婃牸寮忎笉杞崲
      var sendData;
      if (
        (isQueryStringData(method) || options.processData)
        && options.data
        && [ArrayBuffer, Blob, Document, FormData].indexOf(options.data.constructor) < 0
      ) {
        sendData = isString(options.data) ? options.data : $.param(options.data);
      } else {
        sendData = options.data;
      }

      // 瀵逛簬 GET銆丠EAD 绫诲瀷鐨勮姹傦紝鎶 data 鏁版嵁娣诲姞鍒 URL 涓璡n      if (isQueryStringData(method) && sendData) {
        // 鏌ヨ瀛楃涓叉嫾鎺ュ埌 URL 涓璡n        options.url = appendQuery(options.url, sendData);
        sendData = null;
      }

      // JSONP
      if (options.dataType === 'jsonp') {
        // URL 涓坊鍔犺嚜鍔ㄧ敓鎴愮殑鍥炶皟鍑芥暟鍚峔n        var callbackName = isFunction(options.jsonpCallback)
          ? options.jsonpCallback()
          : options.jsonpCallback;
        var requestUrl = appendQuery(options.url, ((options.jsonp) + "=" + callbackName));

        eventParams.options = options;

        triggerEvent(ajaxEvent.ajaxStart, eventParams);
        triggerCallback('beforeSend', null);

        if (isCanceled) {
          return undefined;
        }

        var abortTimeout;

        // 鍒涘缓 script
        var script = document.createElement('script');
        script.type = 'text/javascript';

        // 鍒涘缓 script 澶辫触
        script.onerror = function () {
          if (abortTimeout) {
            clearTimeout(abortTimeout);
          }

          triggerEvent(ajaxEvent.ajaxError, eventParams);
          triggerCallback('error', null, 'scripterror');

          triggerEvent(ajaxEvent.ajaxComplete, eventParams);
          triggerCallback('complete', null, 'scripterror');
        };

        script.src = requestUrl;

        // 澶勭悊
        window[callbackName] = function (data) {
          if (abortTimeout) {
            clearTimeout(abortTimeout);
          }

          eventParams.data = data;

          triggerEvent(ajaxEvent.ajaxSuccess, eventParams);
          triggerCallback('success', data, 'success', null);

          $(script).remove();
          script = null;
          delete window[callbackName];
        };

        $('head').append(script);

        if (options.timeout > 0) {
          abortTimeout = setTimeout(function () {
            $(script).remove();
            script = null;

            triggerEvent(ajaxEvent.ajaxError, eventParams);
            triggerCallback('error', null, 'timeout');
          }, options.timeout);
        }

        return undefined;
      }

      // GET/HEAD 璇锋眰鐨勭紦瀛樺鐞哱n      if (isQueryStringData(method) && !options.cache) {
        options.url = appendQuery(options.url, ("_=" + (Date.now())));
      }

      // 鍒涘缓 XHR
      var xhr = new XMLHttpRequest();

      xhr.open(method, options.url, options.async, options.username, options.password);

      if (
        options.contentType
        || (
          sendData
          && !isQueryStringData(method)
          && options.contentType !== false
        )
      ) {
        xhr.setRequestHeader('Content-Type', options.contentType);
      }

      // 璁剧疆 Accept
      if (options.dataType === 'json') {
        xhr.setRequestHeader('Accept', 'application/json, text/javascript');
      }

      // 娣诲姞 headers
      if (options.headers) {
        each(options.headers, function (key, value) {
          xhr.setRequestHeader(key, value);
        });
      }

      // 妫€鏌ユ槸鍚︽槸璺ㄥ煙璇锋眰
      if (options.crossDomain === undefined) {
        options.crossDomain = /^([\w-]+:)?\/\/([^/]+)/.test(options.url)
          && RegExp.$2 !== window.location.host;
      }

      if (!options.crossDomain) {
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      }

      if (options.xhrFields) {
        each(options.xhrFields, function (key, value) {
          xhr[key] = value;
        });
      }

      eventParams.xhr = xhr;
      eventParams.options = options;

      var xhrTimeout;

      xhr.onload = function () {
        if (xhrTimeout) {
          clearTimeout(xhrTimeout);
        }

        // 鍖呭惈鎴愬姛鎴栭敊璇唬鐮佺殑瀛楃涓瞈n        var textStatus;

        // AJAX 杩斿洖鐨 HTTP 鍝嶅簲鐮佹槸鍚﹁〃绀烘垚鍔焅n        var isHttpStatusSuccess = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 0;

        var responseData;

        if (isHttpStatusSuccess) {
          if (xhr.status === 204 || method === 'HEAD') {
            textStatus = 'nocontent';
          } else if (xhr.status === 304) {
            textStatus = 'notmodified';
          } else {
            textStatus = 'success';
          }

          if (options.dataType === 'json') {
            try {
              responseData = JSON.parse(xhr.responseText);
              eventParams.data = responseData;
            } catch (err) {
              textStatus = 'parsererror';

              triggerEvent(ajaxEvent.ajaxError, eventParams);
              triggerCallback('error', xhr, textStatus);
            }

            if (textStatus !== 'parsererror') {
              triggerEvent(ajaxEvent.ajaxSuccess, eventParams);
              triggerCallback('success', responseData, textStatus, xhr);
            }
          } else {
            responseData = xhr.responseType === 'text' || xhr.responseType === ''
              ? xhr.responseText
              : xhr.response;
            eventParams.data = responseData;

            triggerEvent(ajaxEvent.ajaxSuccess, eventParams);
            triggerCallback('success', responseData, textStatus, xhr);
          }
        } else {
          textStatus = 'error';

          triggerEvent(ajaxEvent.ajaxError, eventParams);
          triggerCallback('error', xhr, textStatus);
        }

        // statusCode
        each([globals.statusCode, options.statusCode], function (i, func) {
          if (func && func[xhr.status]) {
            if (isHttpStatusSuccess) {
              func[xhr.status](responseData, textStatus, xhr);
            } else {
              func[xhr.status](xhr, textStatus);
            }
          }
        });

        triggerEvent(ajaxEvent.ajaxComplete, eventParams);
        triggerCallback('complete', xhr, textStatus);
      };

      xhr.onerror = function () {
        if (xhrTimeout) {
          clearTimeout(xhrTimeout);
        }

        triggerEvent(ajaxEvent.ajaxError, eventParams);
        triggerCallback('error', xhr, xhr.statusText);

        triggerEvent(ajaxEvent.ajaxComplete, eventParams);
        triggerCallback('complete', xhr, 'error');
      };

      xhr.onabort = function () {
        var textStatus = 'abort';

        if (xhrTimeout) {
          textStatus = 'timeout';
          clearTimeout(xhrTimeout);
        }

        triggerEvent(ajaxEvent.ajaxError, eventParams);
        triggerCallback('error', xhr, textStatus);

        triggerEvent(ajaxEvent.ajaxComplete, eventParams);
        triggerCallback('complete', xhr, textStatus);
      };

      // ajax start 鍥炶皟
      triggerEvent(ajaxEvent.ajaxStart, eventParams);
      triggerCallback('beforeSend', xhr);

      if (isCanceled) {
        return xhr;
      }

      // Timeout
      if (options.timeout > 0) {
        xhrTimeout = setTimeout(function () {
          xhr.abort();
        }, options.timeout);
      }

      // 鍙戦€ XHR
      xhr.send(sendData);

      return xhr;
    },
  });

  // 鐩戝惉鍏ㄥ眬浜嬩欢
  //
  // 閫氳繃 $(document).on('success.mdui.ajax', function (event, params) {}) 璋冪敤鏃讹紝鍖呭惈涓や釜鍙傛暟
  // event: 浜嬩欢瀵硅薄
  // params: {
  //   xhr: XMLHttpRequest 瀵硅薄
  //   options: ajax 璇锋眰鐨勯厤缃弬鏁癨n  //   data: ajax 璇锋眰杩斿洖鐨勬暟鎹甛n  // }

  // 鍏ㄥ眬 Ajax 浜嬩欢蹇嵎鏂规硶
  // $(document).ajaxStart(function (event, xhr, options) {})
  // $(document).ajaxSuccess(function (event, xhr, options, data) {})
  // $(document).ajaxError(function (event, xhr, options) {})
  // $(document).ajaxComplete(function (event, xhr, options) {})
  each(ajaxEvent, function (name, eventName) {
    $.fn[name] = function (fn) {
      return this.on(eventName, function (e, params) {
        fn(e, params.xhr, params.options, params.data);
      });
    };
  });

  return $;

}());

/**
 * =============================================================================
 * ************   Mutation   ************
 * =============================================================================
 */

(function () {
  /**
   * API 鍒濆鍖栦唬鐞? 褰 DOM 绐佸彉鍐嶆鎵ц浠ｇ悊鐨勫垵濮嬪寲鍑芥暟. 浣跨敤鏂规硶:
   *
   * 1. 浠ｇ悊缁勪欢 API 鎵ц鍒濆鍖栧嚱鏁? selector 蹇呴』涓哄瓧绗︿覆.
   *    mdui.mutation(selector, apiInit);
   *    mutation 浼氭墽琛 $(selector).each(apiInit)
   *
   * 2. 绐佸彉鏃? 鍐嶆鎵ц浠ｇ悊鐨勫垵濮嬪寲鍑芥暟
   *    mdui.mutation()        绛変环 $(document).mutation()
   *    $(selector).mutation() 鍦 selector 鑺傜偣鍐呰繘琛 API 鍒濆鍖朶n   *
   * 鍘熺悊:
   *
   *    mutation 鎵ц浜 $().data('mdui.mutation', [selector]).
   *    褰撳厓绱犺閲嶆瀯鏃? 璇ユ暟鎹細涓㈠け, 鐢辨鍒ゆ柇鏄惁绐佸彉.
   *
   * 鎻愮ず:
   *
   *    绫讳技 Drawer 鍙互浣跨敤濮旀墭浜嬩欢瀹屾垚.
   *    绫讳技 Collapse 闇€瑕佺煡閬 DOM 鍙戠敓绐佸彉, 骞跺啀娆¤繘琛屽垵濮嬪寲.
   */
  var entries = { };

  function mutation(selector, apiInit, that, i, item) {
    var $this = $(that);
    var m = $this.data('mdui.mutation');

    if (!m) {
      m = [];
      $this.data('mdui.mutation', m);
    }

    if (m.indexOf(selector) === -1) {
      m.push(selector);
      apiInit.call(that, i, item);
    }
  }

  $.fn.extend({
    mutation: function () {
      return this.each(function (i, item) {
        var $this = $(this);
        $.each(entries, function (selector, apiInit) {
          if ($this.is(selector)) {
            mutation(selector, apiInit, $this[0], i, item);
          }

          $this.find(selector).each(function (i, item) {
            mutation(selector, apiInit, this, i, item);
          });
        });
      });
    },
  });

  mdui.mutation = function (selector, apiInit) {
    if (typeof selector !== 'string' || typeof apiInit !== 'function') {
      $(document).mutation();
      return;
    }

    entries[selector] = apiInit;
    $(selector).each(function (i, item) {
      mutation(selector, apiInit, this, i, item);
    });
  };

})();

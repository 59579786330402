/**
 * =============================================================================
 * ************   Slider 婊戝潡   ************
 * =============================================================================
 */

(function () {

  /**
   * 婊戝潡鐨勫€煎彉鏇村悗淇敼婊戝潡鏍峰紡
   * @param $slider
   */
  var updateValueStyle = function ($slider) {
    var data = $slider.data();

    var $track = data.$track;
    var $fill = data.$fill;
    var $thumb = data.$thumb;
    var $input = data.$input;
    var min = data.min;
    var max = data.max;
    var isDisabled = data.disabled;
    var isDiscrete = data.discrete;
    var $thumbText = data.$thumbText;
    var value = $input.val();
    var percent = (value - min) / (max - min) * 100;

    $fill.width(percent + '%');
    $track.width((100 - percent) + '%');

    if (isDisabled) {
      $fill.css('padding-right', '6px');
      $track.css('padding-left', '6px');
    }

    $thumb.css('left', percent + '%');

    if (isDiscrete) {
      $thumbText.text(value);
    }

    $slider[parseFloat(percent) === 0 ? 'addClass' : 'removeClass']('mdui-slider-zero');
  };

  /**
   * 閲嶆柊鍒濆鍖朶n   * @param $slider
   */
  var reInit = function ($slider) {
    var $track = $('<div class="mdui-slider-track"></div>');
    var $fill = $('<div class="mdui-slider-fill"></div>');
    var $thumb = $('<div class="mdui-slider-thumb"></div>');
    var $input = $slider.find('input[type="range"]');

    // 绂佺敤鐘舵€乗n    var isDisabled = $input[0].disabled;
    $slider[isDisabled ? 'addClass' : 'removeClass']('mdui-slider-disabled');

    // 閲嶆柊濉厖 HTML
    $slider.find('.mdui-slider-track').remove();
    $slider.find('.mdui-slider-fill').remove();
    $slider.find('.mdui-slider-thumb').remove();
    $slider.append($track).append($fill).append($thumb);

    // 闂寸画鍨嬫粦鍧梊n    var isDiscrete = $slider.hasClass('mdui-slider-discrete');

    var $thumbText;
    if (isDiscrete) {
      $thumbText = $('<span></span>');
      $thumb.empty().append($thumbText);
    }

    $slider.data({
      $track: $track,
      $fill: $fill,
      $thumb: $thumb,
      $input: $input,
      min: $input.attr('min'),    // 婊戝潡鏈€灏忓€糪n      max: $input.attr('max'),    // 婊戝潡鏈€澶у€糪n      disabled: isDisabled,       // 鏄惁绂佺敤鐘舵€乗n      discrete: isDiscrete,       // 鏄惁鏄棿缁瀷婊戝潡
      $thumbText: $thumbText,      // 闂寸画鍨嬫粦鍧楃殑鏁板€糪n    });

    // 璁剧疆榛樿鍊糪n    updateValueStyle($slider);
  };

  var rangeSelector = '.mdui-slider input[type="range"]';

  $document

    // 婊戝姩婊戝潡浜嬩欢
    .on('input change', rangeSelector, function () {
      var $slider = $(this).parent();
      updateValueStyle($slider);
    })

    // 寮€濮嬭Е鎽告粦鍧椾簨浠禱n    .on(TouchHandler.start, rangeSelector, function (e) {
      if (!TouchHandler.isAllow(e)) {
        return;
      }

      TouchHandler.register(e);

      if (!this.disabled) {
        var $slider = $(this).parent();
        $slider.addClass('mdui-slider-focus');
      }
    })

    // 缁撴潫瑙︽懜婊戝潡浜嬩欢
    .on(TouchHandler.end, rangeSelector, function (e) {
      if (!TouchHandler.isAllow(e)) {
        return;
      }

      if (!this.disabled) {
        var $slider = $(this).parent();
        $slider.removeClass('mdui-slider-focus');
      }
    })

    .on(TouchHandler.unlock, rangeSelector, TouchHandler.register);

  /**
   * 閲嶆柊鍒濆鍖栨粦鍧楋紙寮哄埗閲嶆柊鍒濆鍖栵級
   */
  mdui.updateSliders = function () {
    $(arguments.length ? arguments[0] : '.mdui-slider').each(function () {
      reInit($(this));
    });
  };

  $(function () {
    /**
     * 椤甸潰鍔犺浇瀹屽悗鑷姩鍒濆鍖栵紙鏈垵濮嬪寲鏃讹紝鍙互璋冪敤璇ユ柟娉曞垵濮嬪寲锛塡n     */
    mdui.mutation('.mdui-slider', function () {
      reInit($(this));
    });
  });
})();

/**
 * =============================================================================
 * ************   mdui.confirm(text, title, onConfirm, onCancel, options)   ************
 * ************   mdui.confirm(text, onConfirm, onCancel, options)          ************
 * =============================================================================
 */

mdui.confirm = function (text, title, onConfirm, onCancel, options) {

  // title 鍙傛暟鍙€塡n  if (typeof title === 'function') {
    title = '';
    onConfirm = arguments[1];
    onCancel = arguments[2];
    options = arguments[3];
  }

  if (onConfirm === undefined) {
    onConfirm = function () {};
  }

  if (onCancel === undefined) {
    onCancel = function () {};
  }

  if (options === undefined) {
    options = {};
  }

  /**
   * 榛樿鍙傛暟
   */
  var DEFAULT = {
    confirmText: 'ok',            // 纭鎸夐挳鐨勬枃鏈琝n    cancelText: 'cancel',         // 鍙栨秷鎸夐挳鐨勬枃鏈琝n    history: true,                // 鐩戝惉 hashchange 浜嬩欢
    modal: false,                 // 鏄惁妯℃€佸寲瀵硅瘽妗嗭紝涓 false 鏃剁偣鍑诲璇濇澶栭潰鍖哄煙鍏抽棴瀵硅瘽妗嗭紝涓 true 鏃朵笉鍏抽棴
    closeOnEsc: true,             // 鎸変笅 esc 鍏抽棴瀵硅瘽妗哱n  };

  options = $.extend({}, DEFAULT, options);

  return mdui.dialog({
    title: title,
    content: text,
    buttons: [
      {
        text: options.cancelText,
        bold: false,
        close: true,
        onClick: onCancel,
      },
      {
        text: options.confirmText,
        bold: false,
        close: true,
        onClick: onConfirm,
      },
    ],
    cssClass: 'mdui-dialog-confirm',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
  });
};

/**
 * =============================================================================
 * ************   Headroom.js   ************
 * =============================================================================
 */

mdui.Headroom = (function () {

  /**
   * 榛樿鍙傛暟
   * @type {{}}
   */
  var DEFAULT = {
    tolerance: 5,                                 // 婊氬姩鏉℃粴鍔ㄥ灏戣窛绂诲紑濮嬮殣钘忔垨鏄剧ず鍏冪礌锛寋down: num, up: num}锛屾垨鏁板瓧
    offset: 0,                                    // 鍦ㄩ〉闈㈤《閮ㄥ灏戣窛绂诲唴婊氬姩涓嶄細闅愯棌鍏冪礌
    initialClass: 'mdui-headroom',                // 鍒濆鍖栨椂娣诲姞鐨勭被
    pinnedClass: 'mdui-headroom-pinned-top',      // 鍏冪礌鍥哄畾鏃舵坊鍔犵殑绫籠n    unpinnedClass: 'mdui-headroom-unpinned-top',  // 鍏冪礌闅愯棌鏃舵坊鍔犵殑绫籠n  };

  /**
   * Headroom
   * @param selector
   * @param opts
   * @constructor
   */
  function Headroom(selector, opts) {
    var _this = this;

    _this.$headroom = $(selector).eq(0);
    if (!_this.$headroom.length) {
      return;
    }

    // 宸查€氳繃鑷畾涔夊睘鎬у疄渚嬪寲杩囷紝涓嶅啀閲嶅瀹炰緥鍖朶n    var oldInst = _this.$headroom.data('mdui.headroom');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));

    // 鏁板€艰浆涓 {down: bum, up: num}
    var tolerance = _this.options.tolerance;
    if (tolerance !== Object(tolerance)) {
      _this.options.tolerance = {
        down: tolerance,
        up: tolerance,
      };
    }

    _this._init();
  }

  /**
   * 鍒濆鍖朶n   * @private
   */
  Headroom.prototype._init = function () {
    var _this = this;

    _this.state = 'pinned';
    _this.$headroom
      .addClass(_this.options.initialClass)
      .removeClass(_this.options.pinnedClass + ' ' + _this.options.unpinnedClass);

    _this.inited = false;
    _this.lastScrollY = 0;

    _this._attachEvent();
  };

  /**
   * 鐩戝惉婊氬姩浜嬩欢
   * @private
   */
  Headroom.prototype._attachEvent = function () {
    var _this = this;

    if (!_this.inited) {
      _this.lastScrollY = window.pageYOffset;
      _this.inited = true;

      $window.on('scroll', function () {
        _this._scroll();
      });
    }
  };

  /**
   * 婊氬姩鏃剁殑澶勭悊
   * @private
   */
  Headroom.prototype._scroll = function () {
    var _this = this;
    _this.rafId = window.requestAnimationFrame(function () {
      var currentScrollY = window.pageYOffset;
      var direction = currentScrollY > _this.lastScrollY ? 'down' : 'up';
      var toleranceExceeded =
        Math.abs(currentScrollY - _this.lastScrollY) >=
        _this.options.tolerance[direction];

      if (
        currentScrollY > _this.lastScrollY &&
        currentScrollY >= _this.options.offset &&
        toleranceExceeded) {
        _this.unpin();
      } else if (
        (currentScrollY < _this.lastScrollY && toleranceExceeded) ||
        currentScrollY <= _this.options.offset
      ) {
        _this.pin();
      }

      _this.lastScrollY = currentScrollY;
    });
  };

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   * @param inst
   */
  var transitionEnd = function (inst) {
    if (inst.state === 'pinning') {
      inst.state = 'pinned';
      componentEvent('pinned', 'headroom', inst, inst.$headroom);
    }

    if (inst.state === 'unpinning') {
      inst.state = 'unpinned';
      componentEvent('unpinned', 'headroom', inst, inst.$headroom);
    }
  };

  /**
   * 鍥哄畾浣廫n   */
  Headroom.prototype.pin = function () {
    var _this = this;

    if (
      _this.state === 'pinning' ||
      _this.state === 'pinned' ||
      !_this.$headroom.hasClass(_this.options.initialClass)
    ) {
      return;
    }

    componentEvent('pin', 'headroom', _this, _this.$headroom);

    _this.state = 'pinning';

    _this.$headroom
      .removeClass(_this.options.unpinnedClass)
      .addClass(_this.options.pinnedClass)
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * 涓嶅浐瀹氫綇
   */
  Headroom.prototype.unpin = function () {
    var _this = this;

    if (
      _this.state === 'unpinning' ||
      _this.state === 'unpinned' ||
      !_this.$headroom.hasClass(_this.options.initialClass)
    ) {
      return;
    }

    componentEvent('unpin', 'headroom', _this, _this.$headroom);

    _this.state = 'unpinning';

    _this.$headroom
      .removeClass(_this.options.pinnedClass)
      .addClass(_this.options.unpinnedClass)
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * 鍚敤
   */
  Headroom.prototype.enable = function () {
    var _this = this;

    if (!_this.inited) {
      _this._init();
    }
  };

  /**
   * 绂佺敤
   */
  Headroom.prototype.disable = function () {
    var _this = this;

    if (_this.inited) {
      _this.inited = false;
      _this.$headroom
        .removeClass([
          _this.options.initialClass,
          _this.options.pinnedClass,
          _this.options.unpinnedClass,
        ].join(' '));

      $window.off('scroll', function () {
        _this._scroll();
      });

      window.cancelAnimationFrame(_this.rafId);
    }
  };

  /**
   * 鑾峰彇褰撳墠鐘舵€ pinning | pinned | unpinning | unpinned
   */
  Headroom.prototype.getState = function () {
    return this.state;
  };

  return Headroom;

})();

/**
 * =============================================================================
 * ************   mdui.dialog(options)   ************
 * =============================================================================
 */

mdui.dialog = function (options) {

  /**
   * 榛樿鍙傛暟
   */
  var DEFAULT = {
    title: '',                // 鏍囬
    content: '',              // 鏂囨湰
    buttons: [],              // 鎸夐挳
    stackedButtons: false,    // 鍨傜洿鎺掑垪鎸夐挳
    cssClass: '',             // 鍦 Dialog 涓婃坊鍔犵殑 CSS 绫籠n    history: true,            // 鐩戝惉 hashchange 浜嬩欢
    overlay: true,            // 鏄惁鏄剧ず閬僵
    modal: false,             // 鏄惁妯℃€佸寲瀵硅瘽妗哱n    closeOnEsc: true,         // 鎸変笅 esc 鏃跺叧闂璇濇
    destroyOnClosed: true,    // 鍏抽棴鍚庨攢姣乗n    onOpen: function () {     // 鎵撳紑鍔ㄧ敾寮€濮嬫椂鐨勫洖璋僜n    },

    onOpened: function () {   // 鎵撳紑鍔ㄧ敾缁撴潫鍚庣殑鍥炶皟
    },

    onClose: function () {    // 鍏抽棴鍔ㄧ敾寮€濮嬫椂鐨勫洖璋僜n    },

    onClosed: function () {   // 鍏抽棴鍔ㄧ敾缁撴潫鏃剁殑鍥炶皟
    },
  };

  /**
   * 鎸夐挳鐨勯粯璁ゅ弬鏁癨n   */
  var DEFAULT_BUTTON = {
    text: '',                   // 鎸夐挳鏂囨湰
    bold: false,                // 鎸夐挳鏂囨湰鏄惁鍔犵矖
    close: true,                // 鐐瑰嚮鎸夐挳鍚庡叧闂璇濇
    onClick: function (inst) {  // 鐐瑰嚮鎸夐挳鐨勫洖璋僜n    },
  };

  // 鍚堝苟鍙傛暟
  options = $.extend({}, DEFAULT, (options || {}));
  $.each(options.buttons, function (i, button) {
    options.buttons[i] = $.extend({}, DEFAULT_BUTTON, button);
  });

  // 鎸夐挳鐨 HTML
  var buttonsHTML = '';
  if (options.buttons.length) {
    buttonsHTML =
      '<div class="mdui-dialog-actions ' +
        (options.stackedButtons ? 'mdui-dialog-actions-stacked' : '') +
      '">';
    $.each(options.buttons, function (i, button) {
      buttonsHTML +=
        '<a href="javascript:void(0)" ' +
          'class="mdui-btn mdui-ripple mdui-text-color-primary ' +
          (button.bold ? 'mdui-btn-bold' : '') + '">' +
          button.text +
        '</a>';
    });

    buttonsHTML += '</div>';
  }

  // Dialog 鐨 HTML
  var HTML =
    '<div class="mdui-dialog ' + options.cssClass + '">' +
      (options.title ? '<div class="mdui-dialog-title">' + options.title + '</div>' : '') +
      (options.content ? '<div class="mdui-dialog-content">' + options.content + '</div>' : '') +
      buttonsHTML +
    '</div>';

  // 瀹炰緥鍖 Dialog
  var inst = new mdui.Dialog(HTML, {
    history: options.history,
    overlay: options.overlay,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
    destroyOnClosed: options.destroyOnClosed,
  });

  // 缁戝畾鎸夐挳浜嬩欢
  if (options.buttons.length) {
    inst.$dialog.find('.mdui-dialog-actions .mdui-btn').each(function (i, button) {
      $(button).on('click', function () {
        if (typeof options.buttons[i].onClick === 'function') {
          options.buttons[i].onClick(inst);
        }

        if (options.buttons[i].close) {
          inst.close();
        }
      });
    });
  }

  // 缁戝畾鎵撳紑鍏抽棴浜嬩欢
  if (typeof options.onOpen === 'function') {
    inst.$dialog
      .on('open.mdui.dialog', function () {
        options.onOpen(inst);
      })
      .on('opened.mdui.dialog', function () {
        options.onOpened(inst);
      })
      .on('close.mdui.dialog', function () {
        options.onClose(inst);
      })
      .on('closed.mdui.dialog', function () {
        options.onClosed(inst);
      });
  }

  inst.open();

  return inst;
};

/**
 * =============================================================================
 * ************   Text Field 鏂囨湰妗   ************
 * =============================================================================
 */

(function () {

  var getProp = function (obj, prop) {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      obj[prop] !== undefined &&
      obj[prop]
    ) ? obj[prop] : false;
  };

  /**
   * 杈撳叆妗嗕簨浠禱n   * @param e
   */
  var inputEvent = function (e) {
    var input = e.target;
    var $input = $(input);
    var event = e.type;
    var value = $input.val();

    // reInit 涓 true 鏃讹紝闇€瑕侀噸鏂板垵濮嬪寲鏂囨湰妗哱n    var reInit = getProp(e.detail, 'reInit');

    // domLoadedEvent 涓 true 鏃讹紝涓 DOM 鍔犺浇瀹屾瘯鍚庤嚜鍔ㄨЕ鍙戠殑浜嬩欢
    var domLoadedEvent = getProp(e.detail, 'domLoadedEvent');

    // 鏂囨湰妗嗙被鍨媆n    var type = $input.attr('type') || '';
    if (['checkbox', 'button', 'submit', 'range', 'radio', 'image'].indexOf(type) >= 0) {
      return;
    }

    var $textField = $input.parent('.mdui-textfield');

    // 杈撳叆妗嗘槸鍚﹁仛鐒n    if (event === 'focus') {
      $textField.addClass('mdui-textfield-focus');
    }

    if (event === 'blur') {
      $textField.removeClass('mdui-textfield-focus');
    }

    // 杈撳叆妗嗘槸鍚︿负绌篭n    if (event === 'blur' || event === 'input') {
      $textField[(value && value !== '') ? 'addClass' : 'removeClass']('mdui-textfield-not-empty');
    }

    // 杈撳叆妗嗘槸鍚︾鐢╘n    $textField[input.disabled ? 'addClass' : 'removeClass']('mdui-textfield-disabled');

    // 琛ㄥ崟楠岃瘉
    if ((event === 'input' || event === 'blur') && !domLoadedEvent) {
      if (input.validity) {
        var method = input.validity.valid ? 'removeClass' : 'addClass';
        $textField[method]('mdui-textfield-invalid-html5');
      }
    }

    // textarea 楂樺害鑷姩璋冩暣
    if (e.target.nodeName.toLowerCase() === 'textarea') {

      // IE bug锛歵extarea 鐨勫€间粎涓哄涓崲琛岋紝涓嶅惈鍏朵粬鍐呭鏃讹紝textarea 鐨勯珮搴︿笉鍑嗙‘
      //         姝ゆ椂锛屽湪璁＄畻楂樺害鍓嶏紝鍦ㄥ€肩殑寮€澶村姞鍏ヤ竴涓┖鏍硷紝璁＄畻瀹屽悗锛岀Щ闄ょ┖鏍糪n      var inputValue = $input.val();
      var hasExtraSpace = false;
      if (inputValue.replace(/[\r\n]/g, '') === '') {
        $input.val(' ' + inputValue);
        hasExtraSpace = true;
      }

      // 璁剧疆 textarea 楂樺害
      $input.height('');
      var height = $input.height();
      var scrollHeight = input.scrollHeight;

      if (scrollHeight > height) {
        $input.height(scrollHeight);
      }

      // 璁＄畻瀹岋紝杩樺師 textarea 鐨勫€糪n      if (hasExtraSpace) {
        $input.val(inputValue);
      }
    }

    // 瀹炴椂瀛楁暟缁熻
    if (reInit) {
      $textField
        .find('.mdui-textfield-counter')
        .remove();
    }

    var maxlength = $input.attr('maxlength');
    if (maxlength) {
      if (reInit || domLoadedEvent) {
        $('<div class="mdui-textfield-counter">' +
            '<span class="mdui-textfield-counter-inputed"></span> / ' + maxlength +
          '</div>').appendTo($textField);
      }

      $textField.find('.mdui-textfield-counter-inputed').text(value.length.toString());
    }

    // 鍚 甯姪鏂囨湰銆侀敊璇彁绀恒€佸瓧鏁扮粺璁 鏃讹紝澧炲姞鏂囨湰妗嗗簳閮ㄥ唴杈硅窛
    if (
      $textField.find('.mdui-textfield-helper').length ||
      $textField.find('.mdui-textfield-error').length ||
      maxlength
    ) {
      $textField.addClass('mdui-textfield-has-bottom');
    }
  };

  // 缁戝畾浜嬩欢
  $document.on('input focus blur', '.mdui-textfield-input', { useCapture: true }, inputEvent);

  // 鍙睍寮€鏂囨湰妗嗗睍寮€
  $document.on('click', '.mdui-textfield-expandable .mdui-textfield-icon', function () {
    $(this)

      // 灞曞紑鏂囨湰妗哱n      .parents('.mdui-textfield')
      .addClass('mdui-textfield-expanded')

      // 鑱氱劍鍒拌緭鍏ユ
      .find('.mdui-textfield-input')[0].focus();
  });

  // 鍙睍寮€鏂囨湰妗嗗叧闂璡n  $document.on('click', '.mdui-textfield-expanded .mdui-textfield-close', function () {
    $(this)

      // 鍏抽棴鏂囨湰妗哱n      .parents('.mdui-textfield')
      .removeClass('mdui-textfield-expanded')

      // 娓呯┖杈撳叆妗哱n      .find('.mdui-textfield-input')
      .val('');
  });

  /**
   * 閫氳繃 JS 鏇存柊浜嗚〃鍗曞唴瀹癸紝闇€瑕侀噸鏂拌繘琛岃〃鍗曞鐞哱n   * @param- 濡傛灉浼犲叆浜 .mdui-textfield 鎵€鍦ㄧ殑 DOM 鍏冪礌锛屽垯鏇存柊璇ユ枃鏈锛涘惁鍒欙紝鏇存柊鎵€鏈夋枃鏈
   */
  mdui.updateTextFields = function () {
    $(arguments.length ? arguments[0] : '.mdui-textfield').each(function () {
      $(this)
        .find('.mdui-textfield-input')
        .trigger('input', {
          reInit: true,
        });
    });
  };
})();

$(function () {
  /**
   * 鍒濆鍖栨枃鏈
   */
  mdui.mutation('.mdui-textfield', function () {
    $(this)
      .find('.mdui-textfield-input')
      .trigger('input', {
        domLoadedEvent: true,
      });
  });
});

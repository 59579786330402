/**
 * =============================================================================
 * ************   Snackbar   ************
 * =============================================================================
 */

(function () {

  /**
   * 褰撳墠鎵撳紑鐫€鐨 Snackbar
   */
  var currentInst;

  /**
   * 瀵瑰垪鍚峔n   * @type {string}
   */
  var queueName = '__md_snackbar';

  var DEFAULT = {
    timeout: 4000,                  // 鍦ㄧ敤鎴锋病鏈夋搷浣滄椂澶氶暱鏃堕棿鑷姩闅愯棌
    buttonText: '',                 // 鎸夐挳鐨勬枃鏈琝n    buttonColor: '',                // 鎸夐挳鐨勯鑹诧紝鏀寔 blue #90caf9 rgba(...)
    position: 'bottom',             // 浣嶇疆 bottom銆乼op銆乴eft-top銆乴eft-bottom銆乺ight-top銆乺ight-bottom
    closeOnButtonClick: true,       // 鐐瑰嚮鎸夐挳鏃跺叧闂璡n    closeOnOutsideClick: true,      // 瑙︽懜鎴栫偣鍑诲睆骞曞叾浠栧湴鏂规椂鍏抽棴
    onClick: function () {          // 鍦 Snackbar 涓婄偣鍑荤殑鍥炶皟
    },

    onButtonClick: function () {    // 鐐瑰嚮鎸夐挳鐨勫洖璋僜n    },

    onOpen: function () {           // 鎵撳紑鍔ㄧ敾寮€濮嬫椂鐨勫洖璋僜n    },

    onOpened: function () {         // 鎵撳紑鍔ㄧ敾缁撴潫鏃剁殑鍥炶皟
    },

    onClose: function () {          // 鍏抽棴鍔ㄧ敾寮€濮嬫椂鐨勫洖璋僜n    },

    onClosed: function () {         // 鎵撳紑鍔ㄧ敾缁撴潫鏃剁殑鍥炶皟
    },
  };

  /**
   * 鐐瑰嚮 Snackbar 澶栭潰鐨勫尯鍩熷叧闂璡n   * @param e
   */
  var closeOnOutsideClick = function (e) {
    var $target = $(e.target);
    if (!$target.hasClass('mdui-snackbar') && !$target.parents('.mdui-snackbar').length) {
      currentInst.close();
    }
  };

  /**
   * Snackbar 瀹炰緥
   * @param message
   * @param opts
   * @constructor
   */
  function Snackbar(message, opts) {
    var _this = this;

    _this.message = message;
    _this.options = $.extend({}, DEFAULT, (opts || {}));

    // message 鍙傛暟蹇呴』
    if (!_this.message) {
      return;
    }

    _this.state = 'closed';

    _this.timeoutId = false;

    // 鎸夐挳棰滆壊
    var buttonColorStyle = '';
    var buttonColorClass = '';

    if (
      _this.options.buttonColor.indexOf('#') === 0 ||
      _this.options.buttonColor.indexOf('rgb') === 0
    ) {
      buttonColorStyle = 'style="color:' + _this.options.buttonColor + '"';
    } else if (_this.options.buttonColor !== '') {
      buttonColorClass = 'mdui-text-color-' + _this.options.buttonColor;
    }

    // 娣诲姞 HTML
    _this.$snackbar = $(
      '<div class="mdui-snackbar">' +
        '<div class="mdui-snackbar-text">' +
          _this.message +
        '</div>' +
        (_this.options.buttonText ?
          ('<a href="javascript:void(0)" ' +
          'class="mdui-snackbar-action mdui-btn mdui-ripple mdui-ripple-white ' +
            buttonColorClass + '" ' +
            buttonColorStyle + '>' +
            _this.options.buttonText +
          '</a>') :
          ''
        ) +
      '</div>')
      .appendTo(document.body);

    // 璁剧疆浣嶇疆
    _this._setPosition('close');

    _this.$snackbar
      .reflow()
      .addClass('mdui-snackbar-' + _this.options.position);
  }

  /**
   * 璁剧疆 Snackbar 鐨勪綅缃甛n   * @param state
   * @private
   */
  Snackbar.prototype._setPosition = function (state) {
    var _this = this;

    var snackbarHeight = _this.$snackbar[0].clientHeight;
    var position = _this.options.position;

    var translateX;
    var translateY;

    // translateX
    if (position === 'bottom' || position === 'top') {
      translateX = '-50%';
    } else {
      translateX = '0';
    }

    // translateY
    if (state === 'open') {
      translateY = '0';
    } else {
      if (position === 'bottom') {
        translateY = snackbarHeight;
      }

      if (position === 'top') {
        translateY = -snackbarHeight;
      }

      if (position === 'left-top' || position === 'right-top') {
        translateY = -snackbarHeight - 24;
      }

      if (position === 'left-bottom' || position === 'right-bottom') {
        translateY = snackbarHeight + 24;
      }
    }

    _this.$snackbar.transform('translate(' + translateX + ',' + translateY + 'px)');
  };

  /**
   * 鎵撳紑 Snackbar
   */
  Snackbar.prototype.open = function () {
    var _this = this;

    if (!_this.message) {
      return;
    }

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    // 濡傛灉褰撳墠鏈夋鍦ㄦ樉绀虹殑 Snackbar锛屽垯鍏堝姞鍏ラ槦鍒楋紝绛夋棫 Snackbar 鍏抽棴鍚庡啀鎵撳紑
    if (currentInst) {
      queue.queue(queueName, function () {
        _this.open();
      });

      return;
    }

    currentInst = _this;

    // 寮€濮嬫墦寮€
    _this.state = 'opening';
    _this.options.onOpen();

    _this._setPosition('open');

    _this.$snackbar
      .transitionEnd(function () {
        if (_this.state !== 'opening') {
          return;
        }

        _this.state = 'opened';
        _this.options.onOpened();

        // 鏈夋寜閽椂缁戝畾浜嬩欢
        if (_this.options.buttonText) {
          _this.$snackbar
            .find('.mdui-snackbar-action')
            .on('click', function () {
              _this.options.onButtonClick();
              if (_this.options.closeOnButtonClick) {
                _this.close();
              }
            });
        }

        // 鐐瑰嚮 snackbar 鐨勪簨浠禱n        _this.$snackbar.on('click', function (e) {
          if (!$(e.target).hasClass('mdui-snackbar-action')) {
            _this.options.onClick();
          }
        });

        // 鐐瑰嚮 Snackbar 澶栭潰鐨勫尯鍩熷叧闂璡n        if (_this.options.closeOnOutsideClick) {
          $document.on(TouchHandler.start, closeOnOutsideClick);
        }

        // 瓒呮椂鍚庤嚜鍔ㄥ叧闂璡n        if (_this.options.timeout) {
          _this.timeoutId = setTimeout(function () {
            _this.close();
          }, _this.options.timeout);
        }
      });
  };

  /**
   * 鍏抽棴 Snackbar
   */
  Snackbar.prototype.close = function () {
    var _this = this;

    if (!_this.message) {
      return;
    }

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    if (_this.timeoutId) {
      clearTimeout(_this.timeoutId);
    }

    if (_this.options.closeOnOutsideClick) {
      $document.off(TouchHandler.start, closeOnOutsideClick);
    }

    _this.state = 'closing';
    _this.options.onClose();

    _this._setPosition('close');

    _this.$snackbar
      .transitionEnd(function () {
        if (_this.state !== 'closing') {
          return;
        }

        currentInst = null;
        _this.state = 'closed';
        _this.options.onClosed();
        _this.$snackbar.remove();
        queue.dequeue(queueName);
      });
  };

  /**
   * 鎵撳紑 Snackbar
   * @param message
   * @param opts
   */
  mdui.snackbar = function (message, opts) {
    if (typeof message !== 'string') {
      opts = message;
      message = opts.message;
    }

    var inst = new Snackbar(message, opts);

    inst.open();
    return inst;
  };

})();

/**
 * =============================================================================
 * ************   Fab 娴姩鎿嶄綔鎸夐挳   ************
 * =============================================================================
 */

mdui.Fab = (function () {

  /**
   * 榛樿鍙傛暟
   * @type {{}}
   */
  var DEFAULT = {
    trigger: 'hover',      // 瑙﹀彂鏂瑰紡 ['hover', 'click']
  };

  /**
   * 娴姩鎿嶄綔鎸夐挳瀹炰緥
   * @param selector 閫夋嫨鍣ㄦ垨 HTML 瀛楃涓叉垨 DOM 鍏冪礌鎴 JQ 瀵硅薄
   * @param opts
   * @constructor
   */
  function Fab(selector, opts) {
    var _this = this;

    _this.$fab = $(selector).eq(0);
    if (!_this.$fab.length) {
      return;
    }

    // 宸查€氳繃 data 灞炴€у疄渚嬪寲杩囷紝涓嶅啀閲嶅瀹炰緥鍖朶n    var oldInst = _this.$fab.data('mdui.fab');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.state = 'closed';

    _this.$btn = _this.$fab.find('.mdui-fab');
    _this.$dial = _this.$fab.find('.mdui-fab-dial');
    _this.$dialBtns = _this.$dial.find('.mdui-fab');

    if (_this.options.trigger === 'hover') {
      _this.$btn
        .on('touchstart mouseenter', function () {
          _this.open();
        });

      _this.$fab
        .on('mouseleave', function () {
          _this.close();
        });
    }

    if (_this.options.trigger === 'click') {
      _this.$btn
        .on(TouchHandler.start, function () {
          _this.open();
        });
    }

    // 瑙︽懜灞忓箷鍏朵粬鍦版柟鍏抽棴蹇€熸嫧鍙穃n    $document.on(TouchHandler.start, function (e) {
      if (!$(e.target).parents('.mdui-fab-wrapper').length) {
        _this.close();
      }
    });
  }

  /**
   * 鎵撳紑鑿滃崟
   */
  Fab.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    // 涓鸿彍鍗曚腑鐨勬寜閽坊鍔犱笉鍚岀殑 transition-delay
    _this.$dialBtns.each(function (index, btn) {
      btn.style['transition-delay'] = btn.style['-webkit-transition-delay'] =
        15 * (_this.$dialBtns.length - index) + 'ms';
    });

    _this.$dial
      .css('height', 'auto')
      .addClass('mdui-fab-dial-show');

    // 濡傛灉鎸夐挳涓瓨鍦 .mdui-fab-opened 鐨勫浘鏍囷紝鍒欒繘琛屽浘鏍囧垏鎹n    if (_this.$btn.find('.mdui-fab-opened').length) {
      _this.$btn.addClass('mdui-fab-opened');
    }

    _this.state = 'opening';
    componentEvent('open', 'fab', _this, _this.$fab);

    // 鎵撳紑椤哄簭涓轰粠涓嬪埌涓婇€愪釜鎵撳紑锛屾渶涓婇潰鐨勬墦寮€鍚庢墠琛ㄧず鍔ㄧ敾瀹屾垚
    _this.$dialBtns.eq(0).transitionEnd(function () {
      if (_this.$btn.hasClass('mdui-fab-opened')) {
        _this.state = 'opened';
        componentEvent('opened', 'fab', _this, _this.$fab);
      }
    });
  };

  /**
   * 鍏抽棴鑿滃崟
   */
  Fab.prototype.close = function () {
    var _this = this;

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    // 涓鸿彍鍗曚腑鐨勬寜閽坊鍔犱笉鍚岀殑 transition-delay
    _this.$dialBtns.each(function (index, btn) {
      btn.style['transition-delay'] = btn.style['-webkit-transition-delay'] = 15 * index + 'ms';
    });

    _this.$dial.removeClass('mdui-fab-dial-show');
    _this.$btn.removeClass('mdui-fab-opened');
    _this.state = 'closing';
    componentEvent('close', 'fab', _this, _this.$fab);

    // 浠庝笂寰€涓嬩緷娆″叧闂紝鏈€鍚庝竴涓叧闂悗鎵嶈〃绀哄姩鐢诲畬鎴怽n    _this.$dialBtns.eq(-1).transitionEnd(function () {
      if (!_this.$btn.hasClass('mdui-fab-opened')) {
        _this.state = 'closed';
        componentEvent('closed', 'fab', _this, _this.$fab);
        _this.$dial.css('height', 0);
      }
    });
  };

  /**
   * 鍒囨崲鑿滃崟鐨勬墦寮€鐘舵€乗n   */
  Fab.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * 鑾峰彇褰撳墠鑿滃崟鐘舵€乗n   * @returns {'opening'|'opened'|'closing'|'closed'}
   */
  Fab.prototype.getState = function () {
    return this.state;
  };

  /**
   * 浠ュ姩鐢荤殑褰㈠紡鏄剧ず娴姩鎿嶄綔鎸夐挳
   */
  Fab.prototype.show = function () {
    this.$fab.removeClass('mdui-fab-hide');
  };

  /**
   * 浠ュ姩鐢荤殑褰㈠紡闅愯棌娴姩鎿嶄綔鎸夐挳
   */
  Fab.prototype.hide = function () {
    this.$fab.addClass('mdui-fab-hide');
  };

  return Fab;
})();
